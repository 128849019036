.logo_marcas {
	min-height: 120px;
	display: flex;
    align-items: center;
    justify-content: center;

	.logo_img {
		height: 65px;
	}
	.logo_new {
		height: 100px;
	}
	@media only screen and (min-width: $tiny) {
		min-height: 150px;
	}
}