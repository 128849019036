.separator{
  height:10px;
  display:block;
  width:100%;
  background-color: $separatorColor;
}
.separator.red{
  background-color: $marzoRed;
}

.line-separator {
  border: .5px solid $marzoNew;
}