
.wrapper {
    position:relative;
    transform:translateY(-50%);
    top: 50%;
    margin: 0 auto;
    width:75%;
    overflow:hidden;
}

.wrapper article {
    position:absolute;
    left:100%;
    width:100%;
    font-size: 2em;
    color: black;
    top:0;
    cursor:pointer;
    opacity:0;
    transition:left 1.4s, opacity .5s ease;
    ol {
      font-size: 2.5rem;
      li span {
        background-image: -webkit-gradient(linear, left top, right top, from(#92bf1f), to(#008c36));
        background-image: linear-gradient(to right, #92bf1f, #008c36);
        -webkit-background-clip: text;
        color: transparent;
        text-transform: uppercase;
      }
    }
    p span {
      background-image: -webkit-gradient(linear, left top, right top, from(#92bf1f), to(#008c36));
      background-image: linear-gradient(to right, #92bf1f, #008c36);
      -webkit-background-clip: text;
      color: transparent;
    }
}
.wrapper article.activeText {
    position:relative;
    left:0;
    opacity:1;
}

.wrapper article.slideLeft {
    left:-100%;
    opacity:0;
}

ul li {
    list-style: none;
    padding: 1rem;
}
.dot {
    background-image: linear-gradient(to bottom, red, $marzoNew);
    border: none;
    border-radius: 8px;
    color: white;
    cursor: pointer;
    display: inline-block;
    font-family: 'Roboto', sans-serif;
    font-size: 3.5rem;
    font-weight: 700;
    padding: 1rem 3rem;
    margin: 0 1rem;
    
    
    text-align: center;
}

.active {
  background-color: #c5b59a;
  color: white;
}

  
  
  
  