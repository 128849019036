.single-article {
	padding: 5rem 0;
	@media screen and (min-width: $small) {
		padding: 5rem 0 2rem 0;
	}
	&__header {
		margin-top: 5rem;
		@media screen and (min-width: $small) {
			margin-top: 10rem;
		}
		h1 {
			font-size: 1.8rem;
			@media screen and (min-width: $tiny) {
				font-size: 2rem;
			}
			@media screen and (min-width: $small) {
				font-size: 3rem;
			}
			@media screen and (min-width: $large) {
				font-size: 3.5rem;
			}
		}
	}
	&__aside {
		font-size: inherit;
		display: flex;
		align-items: center;
		margin: 10px 0;
		i {
			margin-right: 5px;
		}
		span {
			display: none;
			@media screen and (min-width: $tiny) {
				display: block;
				margin-left: 5px;
			}
		}
	}
	&__social {
		display: flex;
		align-items: center;
		span {
			color: $marzoRed;
			cursor: pointer;
			margin-left: 10px;
			transition: all .2s;
			&:hover {
				transform: translateX(4px);
			}
		}
	}
	&__content {
		@media screen and (min-width: $tiny) {
			text-align: justify;
			&--cat {
				@media screen and (min-width: $tiny) {
					display: none;
				}
			}
		}	
	}
	&__video {
		position: relative;
		padding-bottom: 56.25%; /*panorámico*/
		padding-top: 25px;
		height: 0;
	}
	&__video-element iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

.continuar {
	margin: 3rem 0 6rem;
	p {
		span {
			color: $marzoRed;
			cursor: pointer;
			display:inline-block;
			margin-left: 10px;
			transition: all .2s;
			&:hover {
				transform: rotate(90deg);
			}
		}
	}
	ul {
		list-style: none;
		padding: 0;
	}
	li a {
		&,
		&:link,
		&:visited {
			border-radius: 3px;
			display: inline-block;
			background: $lightestGray;
			border: none;
			font-size: 18px;
			padding: 10px 20px 10px 20px;
			text-decoration: none;
			margin-bottom: 10px;
			transition: all .2s;
			&:hover {
			color: $marzoNew;
			transform: translateY(-3px);
			box-shadow: 0 1rem 2rem rgba(black,.2);
			text-decoration: none;
			}
			&:active,
			&:focus {
				outline: none;
				transform: translateY(-1px);
				box-shadow: 0 .5rem 1rem rgba(black,.2);
			}
		}
	}
}


.wp_rp_content ul li{
	list-style: none;
}
.wp_rp_content li{
  a.wp_rp_title{
		text-decoration: none;
		color: black;
			&:hover{
			color: $marzoRed;
		}
  }
}
div.social-sharing {
	ul {
		list-style: none;
		display: inline-flex;
		padding: 0;
		li {			
			a {
				padding-left: 1rem;
				i {
				color: $lightGray;
				font-size: 25px;
				padding: 3px;
				transition: all .2s;
					&:hover{
					color: $marzoNew;
					transform: translateY(-2px);
					}
				}
			}
		}
	}
}
@media only screen and (min-width: $small){
	div.social-sharing a.dc-whatsapp{
		display: none;
	}
}
