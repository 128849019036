.blog_resumen, .nuestras_marcas, .ultimos_proyectos, .colaboradores {
  padding: 100px 0 150px;
  header {
    margin-bottom:50px;
  }
}
.text-center{
  text-align: center;
}
.text-left{
  text-align: left;
}

.visible{
  display: block;
}
.page-header{
  h1{
    padding: 60px 0;
    font-family: 'theano_didotregular';
    font-size: 7rem;
    font-weight: 100;
    line-height: 1.1;
    text-transform: uppercase;
    @media only screen and (max-width: $xxlarge) {
      padding: 0;
    }
    @media only screen and (max-width: $tiny) {
      font-size: 3.5rem;
    }
  }
}
.red-text{
  color: $marzoRed;
}
.not-found {
  font-size: 20rem !important;
  line-height: 1;
  margin:0;
  padding:0;
  font-weight: 700;
}

//Page Common
.theme-page {
  text-align: justify;
  &__tittle {
    color: $marzoNew;
  }
  h3 {
    color: $marzoNew;
  }
  &__text {
    padding: 4rem 0;
  }
}

//Home Colaboraciones img.

.text-center {
  img {
    @media only screen and (max-width: $tiny) {
      padding-top: 1rem;
    }
  }
}

// Wordpress post images fix:
img[class*="wp-image-"] {
  max-width: 100%;
  height: auto;
  margin: 0;
} 

//Video Fluid
.video-fluid {
  max-width: 100%;
  height: auto;
  margin: 0;
}


// breadrumbs:

.breadcrumbs {
  display: block;
  color: $marzoNew;
  padding-top: 1rem;
  @media only screen and (min-width: $large) {
    display: none;
  }
}