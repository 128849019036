.footer {
  background-color: $marzoNew;
  height: 22rem;
	width: 100vw;
  font-size:.8em;
  padding-top: 5rem;
	position: relative;
	color: white;
/*   padding: 5rem 0 1rem; */
    @media screen and (min-width: $xsmall) {
      height: 35rem;
    }
  &__logo {
    width: 150px;
    
    @media only screen and (min-width: $tiny) {
      margin-bottom: 10px;
      width: 250px;
    }
    svg.white-cls-1 {
        fill: white;
    }
  }
  &__box-one,
  &__box-two,
  &__contact {
    align-items: center;
    display: flex;
    height: 3rem;
    justify-content: center;
  }
  &__services-item ul {
    display: flex;
    font-size: 1rem;
    justify-content: center;
    list-style: none;
    padding: 0;
    margin: 2rem 0;
    @media screen and (min-width: $xsmall) {
      font-size: inherit;
    }
    li {
      padding: 3px;
      @media screen and (min-width: $xsmall) {
        padding: 5px;
      }
    }
  }
  &__link {
      color: inherit;
      padding-right: 2rem;
      font-size: 1.5rem;
      span {
        color: inherit;
      }
      &:hover {
        color: black;
      }
    }
  &__social nav {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__social-item {
    padding: 0;
      a {
        svg {
          height: 30px;
          transition: all .2s;
          @media screen and (min-width: $xsmall) {
            height: 42px;
          }
          .cls-1 {
            fill: $marzoNew;
          }
          .cls-2 {
            fill: white;
          }
          &:hover {
            padding: 0 .8rem;
            transform: translateY(-2px);
            .cls-1 {
              fill: $lightestGray;
            }
            .cls-2 {
              fill: $marzoNew;
            }
          }
      }
    } 
  }
  &__copy {
    font-size: 1rem;
    margin: 0.7rem;
    @media screen and (min-width: $xsmall) {
      font-size: inherit;
    }
  }
  &__menu {
    padding: 0 0;
  }
  &__list {
    display: flex;
    font-size: 1rem;
    list-style: none;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
    @media screen and (min-width: $xsmall) {
      font-size: inherit;
    }
    li {
      border-right: 1px solid $lightestGray;
      &:last-child{
        border: none;
      }
      a {
        padding: 0 2px;
        color: white;
        cursor: pointer;
        text-decoration: none;
        &:hover{
          color: black;
        }
      }
    }
    .current-menu-item a {
      color: black !important;
    }
  }
  #legal {
    ul li {
      border-right: none;
      &:last-child{
        border: none;
      }
    }
  }

  &__arrow_up {
		position: absolute;
		right: 3px;
		bottom: 20px;
		cursor: pointer;
		@media screen and (min-width: 560px){
			right: 100px;
			bottom: 100px;
    }
    svg {
      height: 30px;
      transition: all .2s;
      @media screen and (min-width: $xsmall) {
        height: 42px;
      }
      &:hover {
        transform: translateY(-5px);
        .arrow-cls-1 {
          fill: $lightestGray;
        }
        .arrow-cls-2 {
          fill: $marzoNew;
        }
      }
    }
  }
}

