.wpcf7-list-item {
    margin-left: 0 !important;
}

.wpcf7-submit {
    &,
    &:link,
    &:visited {
      background: $marzoNew;
      border-radius: 6px;
      -webkit-border-radius: 6;
      -moz-border-radius: 6;
      display: inline-block;
      border: none;
      color: #ffffff;
      font-size: 18px;
      padding: 10px 20px 10px 20px;
      text-decoration: none;
      margin-bottom: 30px;
      transition: all .2s;
      &:hover {
        background: $lightestGray;
        color: $marzoNew;
        transform: translateY(-3px);
        box-shadow: 0 1rem 2rem rgba(black,.2);
        text-decoration: none;
      }
      &:active,
      &:focus {
          outline: none;
          transform: translateY(-1px);
          box-shadow: 0 .5rem 1rem rgba(black,.2);
      }
    }
}

p small a {
  color: black;
  &:hover {
    color: white;
  }
}

.wpcf7 .wpcf7-form .wpcf7-response-output {
  border-color: white !important;
}


/* Recatpcha Hide visibility */
.grecaptcha-badge { 
  visibility: hidden;
}
