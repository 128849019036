.whatsabble {
   &__header {
        background: url(assets/img/whatsabble_img_bn.jpg) center center;
        background-size: cover;
        background-repeat: repeat;
        height: calc(100vh - 10rem);
        width: 100vw;
        margin-top: 1.875rem;
        position: relative;
   }
   &__sello {
        height: 25rem;
        width: auto;
        position: absolute;
        top: 16%;
        right: 0%;
        transform: translate(-50%,-50%);
   }
   &__claim-one, &__claim-two, &__claim-three {
       background-color: rgba(228, 3, 44, 0.355);
       display: flex;
       align-items: center;
       width: 100%;
       position: absolute;
       top: 91%;
       left: 50%;
       transform: translate(-50%, -50%);      
   }
   &__claim-one {
       height: 11rem;
       z-index: 30;
   }
   &__claim-two {
        height: 15rem;
        z-index: 20;
    }
    &__claim-three {
        height: 20rem;
        z-index: 10;
    }
   &__logo {
       height: 7rem;
       width: auto;
       margin-left: 11rem;
       margin-right: 5rem;
   }
   &__hero-text  {
        color: white;
        font-size: 1.2em;
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
    }
    &__description {
        background-color: $marzoNew;
        text-align: center;
        padding: 10rem 0;
    }
    &__description-container {
        border: 2px solid #fff;
        border-radius: 53px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50rem;
    }
    &__description-claim {
        color: white;
        font-size: 1.7em;
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
    }
    &__description-finger {
        height: 7rem;
        padding-right: 2rem;
        width: auto;
    }
    &__description-text {
        text-align: center;
        margin-top: 2rem;
        p {
            color: white;
            margin: .4rem 0;
        }
    }
    &__game {
        background-color: $lightestGray;
        display: flex;
        height: auto;
        margin: 0;
    }
    &__how-to,
    &__filosofia,
    &__funcionamiento {
        padding: 7rem;
        /* text-align: center; */
        width: 33.333333%;
        p {
            margin: 1rem 0;
            font-size: 2rem;
        }
    }
    &__button {
        background-image: linear-gradient(to bottom, red, $marzoNew);
        border: none;
        border-radius: 8px;
        color: white;
        font-family: 'Roboto', sans-serif;
        font-size: 3.5rem;
        font-weight: 700;
        outline: none;
        padding: 1rem 3rem;
        text-transform: uppercase;
        &:active,
        &:hover {
            background-color: #c5b59a;
        }
    }
    &__article {
        p {
            margin: 1rem 0;
        }
    }
    &__list {
        padding: 0;
        list-style: none;
        text-align: inherit;
    }
    &__list-items {
        list-style: none;
        background-image: -webkit-gradient(linear, left top, right top, from(#92bf1f), to(#008c36));
        background-image: linear-gradient(to right, #92bf1f, #008c36);
        -webkit-background-clip: text;
        color: transparent;
        font-size: 2rem;
    }
    &__words {
        height: 110rem;
        margin: 5rem 0 0 0;
        padding: 5rem 0;
    }
    &__footer {
        text-align: center;
    }
    &__footer-logo {
        height: 7rem;
        margin-top: 10rem;
        width: auto;
    }
    &__hastag {
        margin-bottom: 6rem;    
        background-image: -webkit-gradient(linear, left top, right top, from(#92bf1f), to(#008c36));
        background-image: linear-gradient(to right, #92bf1f, #008c36);
        -webkit-background-clip: text;
        color: transparent;
        font-family: 'Roboto', sans-serif;
        font-size: 7rem;
        font-weight: 400;
    }
    &__iniciativa {
        background-color: $marzoNew;
        height: 15rem;
        padding-top: 5rem;
        text-align: center;
        p {
            color: white;
            font-size: 2rem;
            margin: .4rem 0;
        }
    }
}

#panel-one,
#panel-two, 
#panel-three {
    display: none;
}
