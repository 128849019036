/* .page-title, 
.theme-page__tittle,
.single-article__header {
  background-color: $marzoRed;
  background-image: url(assets/img/acuarela_loop.jpg);
  background-size: cover;
  background-repeat: repeat;
  color:white;
  font-size: .8em;
  height: 20rem;
  padding: 45px 0 10px;
  margin-top: 3em;
  h1 {
    font-size: 4rem;
    padding: 1rem 15px;
  }
} */

#breadcrumbs {
  font-size: 1.5rem;
  margin: 0;
  padding-top: 20px;
  a {
    color: black;
    &:hover{
      color:$marzoNew;
    }
  }
}
