.services-list {
  position: relative;
	height: 35rem;
	width: 100vw;
  text-align: center;
  @media screen and (min-width: $small){
    height: 42rem;
  } 
  @media screen and (min-width: $large){
    height: 75rem;
  } 
  @media screen and (min-width: $xlarge){
    height: calc(100vh - 20px);
  } 
  &__effect {
    position: absolute;
    width: 100%;
    top: 42%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
