.category {
	background-color: $separatorColor;
}

.category article a {
	text-decoration:none;
	color:$marzoRed;
	&:hover{
		color:black;
	}
}
.category article h1{
	font-weight: 100;
	a {
		color: black;
		text-decoration: none;
		&:hover{
			color:$marzoRed;
		}
	}
}

.category article aside {
	display: flex;
	align-items: center;
	i {
		margin-right: 3px;
	}
}