.u-center-text { text-align: center !important; }

/* Utilities Margins */

.u-margin-bottom-small { margin-bottom: 1.5rem !important; }
.u-margin-bottom-medium { 
    margin-bottom: 4rem !important; 
}
.u-margin-bottom-big { 
    margin-bottom: 8rem !important; 
}

.u-margin-top-small { margin-top: 2rem !important; }
.u-margin-top-medium { margin-top: 4rem !important; }
.u-margin-top-big { margin-top: 8rem !important; }
.u-margin-top-huge { margin-top: 10rem !important; }

/* Utilities Paddings */

.u-padding-bottom-small { padding-bottom: 1.5rem !important; }
.u-padding-bottom-medium { 
    padding-bottom: 4rem !important; 
}
.u-padding-bottom-big { 
    padding-bottom: 8rem !important; 
}
.u-padding-top-medium { padding-top: 4rem !important;  }
.u-padding-top-big { padding-top: 8rem !important; }
.u-padding-top-huge { padding-top: 10rem !important; }

/*--------------------------------------------------------------
Img
--------------------------------------------------------------*/

.img-fluid {
    width: 100%;
    height: auto;
}

/*--------------------------------------------------------------
Figures
--------------------------------------------------------------*/

figure {
	margin: 0 auto;
    width: 100%;
    img {
        width: 100%;
        height: auto;
    }
}