.contacto {
    background-color: $marzoNew;
    color: white;
    header {
        font-size: 1.3rem;
        @media screen and (min-width: $small) {
            font-size: inherit;
        }
    }
    &__tittle {
        margin: 8rem 0 1rem 0;
        color: white;
    }
    &__data {
        color: inherit;
        margin: 0px;
        padding-bottom: .5rem;
    }
    &__telefono {
        color: white;
        text-decoration: none;
        &:hover {
            color: black;
        }
    }
    &__fields {
        color: white;
    }
    &__address {
        margin-bottom: 8rem;
    }
}