.contacto_content{
	a {
		color:$marzoRed;
		&:hover{
			color:black;
		}
	}
}

/* .page-template-page-contacto {
	main  {
		@media only screen and (max-width: $tiny) {
			padding-bottom: 10rem;
		  }
	}
} */