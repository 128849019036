.primary-part {
    @media only screen and (min-width : $xlarge) {
        /* height: calc(100vh - 130px); */
        width: 100vw;
    }
/*     @media screen and (min-height: 640px) and (orientation: portrait) { 
        ...
    } */
    
    &__quienes, 
    &__contacto-page {
        @media only screen and (min-width : $large) {
            margin-top: 5rem;
        }       
    }
}
