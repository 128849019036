@charset "UTF-8";
/*!
Theme Name: Theme Name: Marzo, Agencia de Publicidad y Marketing
Author: Marzo Marketing
Author URI: https://marzo.com.es
*/
/* @mixin main-font ($color: #101921) { 
    color: $color;
    font-family: 'Source Sans Pro', sans-serif;
}

@mixin primary-headers ($bold: bold) { 
    font-family: 'Archivo Narrow', sans-serif;
    font-weight: $bold;
    text-transform: uppercase;
}

@mixin secondary-headers ($color: $torimbia-primary-dark, $bold: bold) { 
    color: $color;
    font-family: 'Archivo Narrow', sans-serif;
    font-weight: $bold;
    text-transform: uppercase;
}

@mixin tertiary-headers ($color: $color-grey-dark, $bold: bold) {
    font-family: 'Archivo Narrow', sans-serif;
    font-weight: $bold;
    color: $color;
} */
/*----------------------
Theme image background mixin
------------------------*/
/*
* Solo hay grid, formularios, y responsive utilities
*/
html {
  font-weight: 100;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

body {
  margin: 0;
  overflow-x: hidden; }

::selection {
  background-color: #f0f0f0;
  color: #e4032e; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block; }

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden],
template {
  display: none; }

a {
  background-color: transparent; }

a:active,
a:hover {
  outline: 0; }

abbr[title] {
  border-bottom: 1px dotted; }

b,
strong {
  font-weight: bold; }

dfn {
  font-style: italic; }

h1 {
  font-size: 2em;
  margin: 0.67em 0; }

mark {
  background: #ff0;
  color: #000; }

small {
  font-size: 80%; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

img {
  border: 0; }

svg:not(:root) {
  overflow: hidden; }

figure {
  margin: 1em 40px; }

hr {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0; }

pre {
  overflow: auto; }

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0; }

button {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer; }

button[disabled],
html input[disabled] {
  cursor: default; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

input {
  line-height: normal; }

input[type="checkbox"],
input[type="radio"] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0; }

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto; }

input[type="search"] {
  -webkit-appearance: textfield;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box; }

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

legend {
  border: 0;
  padding: 0; }

textarea {
  overflow: auto; }

optgroup {
  font-weight: bold; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

td,
th {
  padding: 0; }

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

html {
  font-size: 10px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

body {
  font-family: "Times New Roman", Georgia, Serif;
  font-size: 18px;
  line-height: 1.42857143;
  color: #333333;
  background-color: #ffffff; }

input,
button,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

a {
  color: black;
  text-decoration: none; }

a:hover,
a:focus {
  color: #e4032e;
  text-decoration: underline; }

a:focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px; }

figure {
  margin: 0; }

img {
  vertical-align: middle; }

.img-responsive {
  display: block;
  max-width: 100%;
  height: auto; }

.gif-fluid {
  display: block;
  max-width: 100%;
  height: auto;
  margin: auto; }

.img-rounded {
  border-radius: 6px; }

.img-thumbnail {
  padding: 4px;
  line-height: 1.42857143;
  background-color: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 4px;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  display: inline-block;
  max-width: 100%;
  height: auto; }

.img-circle {
  border-radius: 50%; }

hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid #eeeeee; }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0; }

.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  clip: auto; }

[role="button"] {
  cursor: pointer; }

.container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px; }

@media (min-width: 768px) {
  .container {
    width: 750px; } }

@media (min-width: 992px) {
  .container {
    width: 970px; } }

@media (min-width: 1200px) {
  .container {
    width: 1170px; } }

.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px; }

.row {
  margin-left: -15px;
  margin-right: -15px; }

.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px; }

.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12 {
  float: left; }

.col-xs-12 {
  width: 100%; }

.col-xs-11 {
  width: 91.66666667%; }

.col-xs-10 {
  width: 83.33333333%; }

.col-xs-9 {
  width: 75%; }

.col-xs-8 {
  width: 66.66666667%; }

.col-xs-7 {
  width: 58.33333333%; }

.col-xs-6 {
  width: 50%; }

.col-xs-5 {
  width: 41.66666667%; }

.col-xs-4 {
  width: 33.33333333%; }

.col-xs-3 {
  width: 25%; }

.col-xs-2 {
  width: 16.66666667%; }

.col-xs-1 {
  width: 8.33333333%; }

.col-xs-pull-12 {
  right: 100%; }

.col-xs-pull-11 {
  right: 91.66666667%; }

.col-xs-pull-10 {
  right: 83.33333333%; }

.col-xs-pull-9 {
  right: 75%; }

.col-xs-pull-8 {
  right: 66.66666667%; }

.col-xs-pull-7 {
  right: 58.33333333%; }

.col-xs-pull-6 {
  right: 50%; }

.col-xs-pull-5 {
  right: 41.66666667%; }

.col-xs-pull-4 {
  right: 33.33333333%; }

.col-xs-pull-3 {
  right: 25%; }

.col-xs-pull-2 {
  right: 16.66666667%; }

.col-xs-pull-1 {
  right: 8.33333333%; }

.col-xs-pull-0 {
  right: auto; }

.col-xs-push-12 {
  left: 100%; }

.col-xs-push-11 {
  left: 91.66666667%; }

.col-xs-push-10 {
  left: 83.33333333%; }

.col-xs-push-9 {
  left: 75%; }

.col-xs-push-8 {
  left: 66.66666667%; }

.col-xs-push-7 {
  left: 58.33333333%; }

.col-xs-push-6 {
  left: 50%; }

.col-xs-push-5 {
  left: 41.66666667%; }

.col-xs-push-4 {
  left: 33.33333333%; }

.col-xs-push-3 {
  left: 25%; }

.col-xs-push-2 {
  left: 16.66666667%; }

.col-xs-push-1 {
  left: 8.33333333%; }

.col-xs-push-0 {
  left: auto; }

.col-xs-offset-12 {
  margin-left: 100%; }

.col-xs-offset-11 {
  margin-left: 91.66666667%; }

.col-xs-offset-10 {
  margin-left: 83.33333333%; }

.col-xs-offset-9 {
  margin-left: 75%; }

.col-xs-offset-8 {
  margin-left: 66.66666667%; }

.col-xs-offset-7 {
  margin-left: 58.33333333%; }

.col-xs-offset-6 {
  margin-left: 50%; }

.col-xs-offset-5 {
  margin-left: 41.66666667%; }

.col-xs-offset-4 {
  margin-left: 33.33333333%; }

.col-xs-offset-3 {
  margin-left: 25%; }

.col-xs-offset-2 {
  margin-left: 16.66666667%; }

.col-xs-offset-1 {
  margin-left: 8.33333333%; }

.col-xs-offset-0 {
  margin-left: 0%; }

@media (min-width: 768px) {
  .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
    float: left; }
  .col-sm-12 {
    width: 100%; }
  .col-sm-11 {
    width: 91.66666667%; }
  .col-sm-10 {
    width: 83.33333333%; }
  .col-sm-9 {
    width: 75%; }
  .col-sm-8 {
    width: 66.66666667%; }
  .col-sm-7 {
    width: 58.33333333%; }
  .col-sm-6 {
    width: 50%; }
  .col-sm-5 {
    width: 41.66666667%; }
  .col-sm-4 {
    width: 33.33333333%; }
  .col-sm-3 {
    width: 25%; }
  .col-sm-2 {
    width: 16.66666667%; }
  .col-sm-1 {
    width: 8.33333333%; }
  .col-sm-pull-12 {
    right: 100%; }
  .col-sm-pull-11 {
    right: 91.66666667%; }
  .col-sm-pull-10 {
    right: 83.33333333%; }
  .col-sm-pull-9 {
    right: 75%; }
  .col-sm-pull-8 {
    right: 66.66666667%; }
  .col-sm-pull-7 {
    right: 58.33333333%; }
  .col-sm-pull-6 {
    right: 50%; }
  .col-sm-pull-5 {
    right: 41.66666667%; }
  .col-sm-pull-4 {
    right: 33.33333333%; }
  .col-sm-pull-3 {
    right: 25%; }
  .col-sm-pull-2 {
    right: 16.66666667%; }
  .col-sm-pull-1 {
    right: 8.33333333%; }
  .col-sm-pull-0 {
    right: auto; }
  .col-sm-push-12 {
    left: 100%; }
  .col-sm-push-11 {
    left: 91.66666667%; }
  .col-sm-push-10 {
    left: 83.33333333%; }
  .col-sm-push-9 {
    left: 75%; }
  .col-sm-push-8 {
    left: 66.66666667%; }
  .col-sm-push-7 {
    left: 58.33333333%; }
  .col-sm-push-6 {
    left: 50%; }
  .col-sm-push-5 {
    left: 41.66666667%; }
  .col-sm-push-4 {
    left: 33.33333333%; }
  .col-sm-push-3 {
    left: 25%; }
  .col-sm-push-2 {
    left: 16.66666667%; }
  .col-sm-push-1 {
    left: 8.33333333%; }
  .col-sm-push-0 {
    left: auto; }
  .col-sm-offset-12 {
    margin-left: 100%; }
  .col-sm-offset-11 {
    margin-left: 91.66666667%; }
  .col-sm-offset-10 {
    margin-left: 83.33333333%; }
  .col-sm-offset-9 {
    margin-left: 75%; }
  .col-sm-offset-8 {
    margin-left: 66.66666667%; }
  .col-sm-offset-7 {
    margin-left: 58.33333333%; }
  .col-sm-offset-6 {
    margin-left: 50%; }
  .col-sm-offset-5 {
    margin-left: 41.66666667%; }
  .col-sm-offset-4 {
    margin-left: 33.33333333%; }
  .col-sm-offset-3 {
    margin-left: 25%; }
  .col-sm-offset-2 {
    margin-left: 16.66666667%; }
  .col-sm-offset-1 {
    margin-left: 8.33333333%; }
  .col-sm-offset-0 {
    margin-left: 0%; } }

@media (min-width: 992px) {
  .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
    float: left; }
  .col-md-12 {
    width: 100%; }
  .col-md-11 {
    width: 91.66666667%; }
  .col-md-10 {
    width: 83.33333333%; }
  .col-md-9 {
    width: 75%; }
  .col-md-8 {
    width: 66.66666667%; }
  .col-md-7 {
    width: 58.33333333%; }
  .col-md-6 {
    width: 50%; }
  .col-md-5 {
    width: 41.66666667%; }
  .col-md-4 {
    width: 33.33333333%; }
  .col-md-3 {
    width: 25%; }
  .col-md-2 {
    width: 16.66666667%; }
  .col-md-1 {
    width: 8.33333333%; }
  .col-md-pull-12 {
    right: 100%; }
  .col-md-pull-11 {
    right: 91.66666667%; }
  .col-md-pull-10 {
    right: 83.33333333%; }
  .col-md-pull-9 {
    right: 75%; }
  .col-md-pull-8 {
    right: 66.66666667%; }
  .col-md-pull-7 {
    right: 58.33333333%; }
  .col-md-pull-6 {
    right: 50%; }
  .col-md-pull-5 {
    right: 41.66666667%; }
  .col-md-pull-4 {
    right: 33.33333333%; }
  .col-md-pull-3 {
    right: 25%; }
  .col-md-pull-2 {
    right: 16.66666667%; }
  .col-md-pull-1 {
    right: 8.33333333%; }
  .col-md-pull-0 {
    right: auto; }
  .col-md-push-12 {
    left: 100%; }
  .col-md-push-11 {
    left: 91.66666667%; }
  .col-md-push-10 {
    left: 83.33333333%; }
  .col-md-push-9 {
    left: 75%; }
  .col-md-push-8 {
    left: 66.66666667%; }
  .col-md-push-7 {
    left: 58.33333333%; }
  .col-md-push-6 {
    left: 50%; }
  .col-md-push-5 {
    left: 41.66666667%; }
  .col-md-push-4 {
    left: 33.33333333%; }
  .col-md-push-3 {
    left: 25%; }
  .col-md-push-2 {
    left: 16.66666667%; }
  .col-md-push-1 {
    left: 8.33333333%; }
  .col-md-push-0 {
    left: auto; }
  .col-md-offset-12 {
    margin-left: 100%; }
  .col-md-offset-11 {
    margin-left: 91.66666667%; }
  .col-md-offset-10 {
    margin-left: 83.33333333%; }
  .col-md-offset-9 {
    margin-left: 75%; }
  .col-md-offset-8 {
    margin-left: 66.66666667%; }
  .col-md-offset-7 {
    margin-left: 58.33333333%; }
  .col-md-offset-6 {
    margin-left: 50%; }
  .col-md-offset-5 {
    margin-left: 41.66666667%; }
  .col-md-offset-4 {
    margin-left: 33.33333333%; }
  .col-md-offset-3 {
    margin-left: 25%; }
  .col-md-offset-2 {
    margin-left: 16.66666667%; }
  .col-md-offset-1 {
    margin-left: 8.33333333%; }
  .col-md-offset-0 {
    margin-left: 0%; } }

@media (min-width: 1200px) {
  .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12 {
    float: left; }
  .col-lg-12 {
    width: 100%; }
  .col-lg-11 {
    width: 91.66666667%; }
  .col-lg-10 {
    width: 83.33333333%; }
  .col-lg-9 {
    width: 75%; }
  .col-lg-8 {
    width: 66.66666667%; }
  .col-lg-7 {
    width: 58.33333333%; }
  .col-lg-6 {
    width: 50%; }
  .col-lg-5 {
    width: 41.66666667%; }
  .col-lg-4 {
    width: 33.33333333%; }
  .col-lg-3 {
    width: 25%; }
  .col-lg-2 {
    width: 16.66666667%; }
  .col-lg-1 {
    width: 8.33333333%; }
  .col-lg-pull-12 {
    right: 100%; }
  .col-lg-pull-11 {
    right: 91.66666667%; }
  .col-lg-pull-10 {
    right: 83.33333333%; }
  .col-lg-pull-9 {
    right: 75%; }
  .col-lg-pull-8 {
    right: 66.66666667%; }
  .col-lg-pull-7 {
    right: 58.33333333%; }
  .col-lg-pull-6 {
    right: 50%; }
  .col-lg-pull-5 {
    right: 41.66666667%; }
  .col-lg-pull-4 {
    right: 33.33333333%; }
  .col-lg-pull-3 {
    right: 25%; }
  .col-lg-pull-2 {
    right: 16.66666667%; }
  .col-lg-pull-1 {
    right: 8.33333333%; }
  .col-lg-pull-0 {
    right: auto; }
  .col-lg-push-12 {
    left: 100%; }
  .col-lg-push-11 {
    left: 91.66666667%; }
  .col-lg-push-10 {
    left: 83.33333333%; }
  .col-lg-push-9 {
    left: 75%; }
  .col-lg-push-8 {
    left: 66.66666667%; }
  .col-lg-push-7 {
    left: 58.33333333%; }
  .col-lg-push-6 {
    left: 50%; }
  .col-lg-push-5 {
    left: 41.66666667%; }
  .col-lg-push-4 {
    left: 33.33333333%; }
  .col-lg-push-3 {
    left: 25%; }
  .col-lg-push-2 {
    left: 16.66666667%; }
  .col-lg-push-1 {
    left: 8.33333333%; }
  .col-lg-push-0 {
    left: auto; }
  .col-lg-offset-12 {
    margin-left: 100%; }
  .col-lg-offset-11 {
    margin-left: 91.66666667%; }
  .col-lg-offset-10 {
    margin-left: 83.33333333%; }
  .col-lg-offset-9 {
    margin-left: 75%; }
  .col-lg-offset-8 {
    margin-left: 66.66666667%; }
  .col-lg-offset-7 {
    margin-left: 58.33333333%; }
  .col-lg-offset-6 {
    margin-left: 50%; }
  .col-lg-offset-5 {
    margin-left: 41.66666667%; }
  .col-lg-offset-4 {
    margin-left: 33.33333333%; }
  .col-lg-offset-3 {
    margin-left: 25%; }
  .col-lg-offset-2 {
    /* margin-left: 16.66666667%; */ }
  .col-lg-offset-1 {
    margin-left: 8.33333333%; }
  .col-lg-offset-0 {
    margin-left: 0%; } }

fieldset {
  padding: 0;
  margin: 0;
  border: 0;
  min-width: 0; }

legend {
  display: block;
  width: 100%;
  padding: 0;
  margin-bottom: 20px;
  font-size: 21px;
  line-height: inherit;
  color: #333333;
  border: 0;
  border-bottom: 1px solid #e5e5e5; }

label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: bold; }

input[type="search"] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

input[type="radio"],
input[type="checkbox"] {
  margin: 4px 0 0;
  margin-top: 1px \9;
  line-height: normal; }

input[type="file"] {
  display: block; }

input[type="range"] {
  display: block;
  width: 100%; }

select[multiple],
select[size] {
  height: auto; }

input[type="file"]:focus,
input[type="radio"]:focus,
input[type="checkbox"]:focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px; }

output {
  display: block;
  padding-top: 7px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555555; }

.form-control {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555555;
  background-color: #ffffff;
  background-image: none;
  border: 2px solid #F3F3EC;
  border-radius: 2px;
  -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
  -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s; }

.form-control:focus {
  border-color: #66afe9;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6); }

.form-control::-moz-placeholder {
  color: #999999;
  opacity: 1; }

.form-control:-ms-input-placeholder {
  color: #999999; }

.form-control::-webkit-input-placeholder {
  color: #999999; }

.form-control::-ms-expand {
  border: 0;
  background-color: transparent; }

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
  background-color: #eeeeee;
  opacity: 1; }

.form-control[disabled],
fieldset[disabled] .form-control {
  cursor: not-allowed; }

textarea.form-control {
  height: auto; }

input[type="search"] {
  -webkit-appearance: none; }

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  input[type="date"].form-control,
  input[type="time"].form-control,
  input[type="datetime-local"].form-control,
  input[type="month"].form-control {
    line-height: 34px; }
  input[type="date"].input-sm,
  input[type="time"].input-sm,
  input[type="datetime-local"].input-sm,
  input[type="month"].input-sm,
  .input-group-sm input[type="date"],
  .input-group-sm input[type="time"],
  .input-group-sm input[type="datetime-local"],
  .input-group-sm input[type="month"] {
    line-height: 30px; }
  input[type="date"].input-lg,
  input[type="time"].input-lg,
  input[type="datetime-local"].input-lg,
  input[type="month"].input-lg,
  .input-group-lg input[type="date"],
  .input-group-lg input[type="time"],
  .input-group-lg input[type="datetime-local"],
  .input-group-lg input[type="month"] {
    line-height: 46px; } }

.form-group {
  margin-bottom: 15px; }

.radio,
.checkbox {
  position: relative;
  display: block;
  margin-top: 10px;
  margin-bottom: 10px; }

.radio label,
.checkbox label {
  min-height: 20px;
  padding-left: 20px;
  margin-bottom: 0;
  font-weight: normal;
  cursor: pointer; }

.radio input[type="radio"],
.radio-inline input[type="radio"],
.checkbox input[type="checkbox"],
.checkbox-inline input[type="checkbox"] {
  position: absolute;
  margin-left: -20px;
  margin-top: 4px \9; }

.radio + .radio,
.checkbox + .checkbox {
  margin-top: -5px; }

.radio-inline,
.checkbox-inline {
  position: relative;
  display: inline-block;
  padding-left: 20px;
  margin-bottom: 0;
  vertical-align: middle;
  font-weight: normal;
  cursor: pointer; }

.radio-inline + .radio-inline,
.checkbox-inline + .checkbox-inline {
  margin-top: 0;
  margin-left: 10px; }

input[type="radio"][disabled],
input[type="checkbox"][disabled],
input[type="radio"].disabled,
input[type="checkbox"].disabled,
fieldset[disabled] input[type="radio"],
fieldset[disabled] input[type="checkbox"] {
  cursor: not-allowed; }

.radio-inline.disabled,
.checkbox-inline.disabled,
fieldset[disabled] .radio-inline,
fieldset[disabled] .checkbox-inline {
  cursor: not-allowed; }

.radio.disabled label,
.checkbox.disabled label,
fieldset[disabled] .radio label,
fieldset[disabled] .checkbox label {
  cursor: not-allowed; }

.form-control-static {
  padding-top: 7px;
  padding-bottom: 7px;
  margin-bottom: 0;
  min-height: 34px; }

.form-control-static.input-lg,
.form-control-static.input-sm {
  padding-left: 0;
  padding-right: 0; }

.input-sm {
  height: 30px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px; }

select.input-sm {
  height: 30px;
  line-height: 30px; }

textarea.input-sm,
select[multiple].input-sm {
  height: auto; }

.form-group-sm .form-control {
  height: 30px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px; }

.form-group-sm select.form-control {
  height: 30px;
  line-height: 30px; }

.form-group-sm textarea.form-control,
.form-group-sm select[multiple].form-control {
  height: auto; }

.form-group-sm .form-control-static {
  height: 30px;
  min-height: 32px;
  padding: 6px 10px;
  font-size: 12px;
  line-height: 1.5; }

.input-lg {
  height: 46px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333;
  border-radius: 6px; }

select.input-lg {
  height: 46px;
  line-height: 46px; }

textarea.input-lg,
select[multiple].input-lg {
  height: auto; }

.form-group-lg .form-control {
  height: 46px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333;
  border-radius: 6px; }

.form-group-lg select.form-control {
  height: 46px;
  line-height: 46px; }

.form-group-lg textarea.form-control,
.form-group-lg select[multiple].form-control {
  height: auto; }

.form-group-lg .form-control-static {
  height: 46px;
  min-height: 38px;
  padding: 11px 16px;
  font-size: 18px;
  line-height: 1.3333333; }

.has-feedback {
  position: relative; }

.has-feedback .form-control {
  padding-right: 42.5px; }

.form-control-feedback {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  display: block;
  width: 34px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  pointer-events: none; }

.input-lg + .form-control-feedback,
.input-group-lg + .form-control-feedback,
.form-group-lg .form-control + .form-control-feedback {
  width: 46px;
  height: 46px;
  line-height: 46px; }

.input-sm + .form-control-feedback,
.input-group-sm + .form-control-feedback,
.form-group-sm .form-control + .form-control-feedback {
  width: 30px;
  height: 30px;
  line-height: 30px; }

.has-success .help-block,
.has-success .control-label,
.has-success .radio,
.has-success .checkbox,
.has-success .radio-inline,
.has-success .checkbox-inline,
.has-success.radio label,
.has-success.checkbox label,
.has-success.radio-inline label,
.has-success.checkbox-inline label {
  color: #3c763d; }

.has-success .form-control {
  border-color: #3c763d;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075); }

.has-success .form-control:focus {
  border-color: #2b542c;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67b168;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67b168; }

.has-success .input-group-addon {
  color: #3c763d;
  border-color: #3c763d;
  background-color: #dff0d8; }

.has-success .form-control-feedback {
  color: #3c763d; }

.has-warning .help-block,
.has-warning .control-label,
.has-warning .radio,
.has-warning .checkbox,
.has-warning .radio-inline,
.has-warning .checkbox-inline,
.has-warning.radio label,
.has-warning.checkbox label,
.has-warning.radio-inline label,
.has-warning.checkbox-inline label {
  color: #8a6d3b; }

.has-warning .form-control {
  border-color: #8a6d3b;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075); }

.has-warning .form-control:focus {
  border-color: #66512c;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #c0a16b;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #c0a16b; }

.has-warning .input-group-addon {
  color: #8a6d3b;
  border-color: #8a6d3b;
  background-color: #fcf8e3; }

.has-warning .form-control-feedback {
  color: #8a6d3b; }

.has-error .help-block,
.has-error .control-label,
.has-error .radio,
.has-error .checkbox,
.has-error .radio-inline,
.has-error .checkbox-inline,
.has-error.radio label,
.has-error.checkbox label,
.has-error.radio-inline label,
.has-error.checkbox-inline label {
  color: #a94442; }

.has-error .form-control {
  border-color: #a94442;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075); }

.has-error .form-control:focus {
  border-color: #843534;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483; }

.has-error .input-group-addon {
  color: #a94442;
  border-color: #a94442;
  background-color: #f2dede; }

.has-error .form-control-feedback {
  color: #a94442; }

.has-feedback label ~ .form-control-feedback {
  top: 25px; }

.has-feedback label.sr-only ~ .form-control-feedback {
  top: 0; }

.help-block {
  display: block;
  margin-top: 5px;
  margin-bottom: 10px;
  color: #737373; }

@media (min-width: 768px) {
  .form-inline .form-group {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: middle; }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle; }
  .form-inline .form-control-static {
    display: inline-block; }
  .form-inline .input-group {
    display: inline-table;
    vertical-align: middle; }
  .form-inline .input-group .input-group-addon,
  .form-inline .input-group .input-group-btn,
  .form-inline .input-group .form-control {
    width: auto; }
  .form-inline .input-group > .form-control {
    width: 100%; }
  .form-inline .control-label {
    margin-bottom: 0;
    vertical-align: middle; }
  .form-inline .radio,
  .form-inline .checkbox {
    display: inline-block;
    margin-top: 0;
    margin-bottom: 0;
    vertical-align: middle; }
  .form-inline .radio label,
  .form-inline .checkbox label {
    padding-left: 0; }
  .form-inline .radio input[type="radio"],
  .form-inline .checkbox input[type="checkbox"] {
    position: relative;
    margin-left: 0; }
  .form-inline .has-feedback .form-control-feedback {
    top: 0; } }

.form-horizontal .radio,
.form-horizontal .checkbox,
.form-horizontal .radio-inline,
.form-horizontal .checkbox-inline {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 7px; }

.form-horizontal .radio,
.form-horizontal .checkbox {
  min-height: 27px; }

.form-horizontal .form-group {
  margin-left: -15px;
  margin-right: -15px; }

@media (min-width: 768px) {
  .form-horizontal .control-label {
    text-align: right;
    margin-bottom: 0;
    padding-top: 7px; } }

.form-horizontal .has-feedback .form-control-feedback {
  right: 15px; }

@media (min-width: 768px) {
  .form-horizontal .form-group-lg .control-label {
    padding-top: 11px;
    font-size: 18px; } }

@media (min-width: 768px) {
  .form-horizontal .form-group-sm .control-label {
    padding-top: 6px;
    font-size: 12px; } }

.clearfix:before,
.clearfix:after,
.container:before,
.container:after,
.container-fluid:before,
.container-fluid:after,
.row:before,
.row:after,
.form-horizontal .form-group:before,
.form-horizontal .form-group:after {
  content: " ";
  display: table; }

.clearfix:after,
.container:after,
.container-fluid:after,
.row:after,
.form-horizontal .form-group:after {
  clear: both; }

.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto; }

.pull-right {
  float: right !important; }

.pull-left {
  float: left !important; }

.hide {
  display: none !important; }

.show {
  display: block !important; }

.invisible {
  visibility: hidden; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.hidden {
  display: none !important; }

.affix {
  position: fixed; }

@-ms-viewport {
  width: device-width; }

.visible-xs,
.visible-sm,
.visible-md,
.visible-lg {
  display: none !important; }

.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block {
  display: none !important; }

@media (max-width: 767px) {
  .visible-xs {
    display: block !important; }
  table.visible-xs {
    display: table !important; }
  tr.visible-xs {
    display: table-row !important; }
  th.visible-xs,
  td.visible-xs {
    display: table-cell !important; } }

@media (max-width: 767px) {
  .visible-xs-block {
    display: block !important; } }

@media (max-width: 767px) {
  .visible-xs-inline {
    display: inline !important; } }

@media (max-width: 767px) {
  .visible-xs-inline-block {
    display: inline-block !important; } }

/* Menu de hamburguesa visible a partir de 1200 */
@media (min-width: 768px) and (max-width: 1200px) {
  .visible-sm {
    display: block !important; }
  table.visible-sm {
    display: table !important; }
  tr.visible-sm {
    display: table-row !important; }
  th.visible-sm,
  td.visible-sm {
    display: table-cell !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-block {
    display: block !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-inline {
    display: inline !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-inline-block {
    display: inline-block !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md {
    display: block !important; }
  table.visible-md {
    display: table !important; }
  tr.visible-md {
    display: table-row !important; }
  th.visible-md,
  td.visible-md {
    display: table-cell !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-block {
    display: block !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-inline {
    display: inline !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-inline-block {
    display: inline-block !important; } }

@media (min-width: 1200px) {
  .visible-lg {
    display: block !important; }
  table.visible-lg {
    display: table !important; }
  tr.visible-lg {
    display: table-row !important; }
  th.visible-lg,
  td.visible-lg {
    display: table-cell !important; } }

@media (min-width: 1200px) {
  .visible-lg-block {
    display: block !important; } }

@media (min-width: 1200px) {
  .visible-lg-inline {
    display: inline !important; } }

@media (min-width: 1200px) {
  .visible-lg-inline-block {
    display: inline-block !important; } }

@media (max-width: 767px) {
  .hidden-xs {
    display: none !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .hidden-sm {
    display: none !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .hidden-md {
    display: none !important; } }

@media (min-width: 1200px) {
  .hidden-lg {
    display: none !important; } }

.visible-print {
  display: none !important; }

@media print {
  .visible-print {
    display: block !important; }
  table.visible-print {
    display: table !important; }
  tr.visible-print {
    display: table-row !important; }
  th.visible-print,
  td.visible-print {
    display: table-cell !important; } }

.visible-print-block {
  display: none !important; }

@media print {
  .visible-print-block {
    display: block !important; } }

.visible-print-inline {
  display: none !important; }

@media print {
  .visible-print-inline {
    display: inline !important; } }

.visible-print-inline-block {
  display: none !important; }

@media print {
  .visible-print-inline-block {
    display: inline-block !important; } }

@media print {
  .hidden-print {
    display: none !important; } }

.blog_resumen, .nuestras_marcas, .ultimos_proyectos, .colaboradores {
  padding: 100px 0 150px; }
  .blog_resumen header, .nuestras_marcas header, .ultimos_proyectos header, .colaboradores header {
    margin-bottom: 50px; }

.text-center {
  text-align: center; }

.text-left {
  text-align: left; }

.visible {
  display: block; }

.page-header h1 {
  padding: 60px 0;
  font-family: 'theano_didotregular';
  font-size: 7rem;
  font-weight: 100;
  line-height: 1.1;
  text-transform: uppercase; }
  @media only screen and (max-width: 1600px) {
    .page-header h1 {
      padding: 0; } }
  @media only screen and (max-width: 640px) {
    .page-header h1 {
      font-size: 3.5rem; } }

.red-text {
  color: #DA000F; }

.not-found {
  font-size: 20rem !important;
  line-height: 1;
  margin: 0;
  padding: 0;
  font-weight: 700; }

.theme-page {
  text-align: justify; }
  .theme-page__tittle {
    color: #e4032e; }
  .theme-page h3 {
    color: #e4032e; }
  .theme-page__text {
    padding: 4rem 0; }

@media only screen and (max-width: 640px) {
  .text-center img {
    padding-top: 1rem; } }

img[class*="wp-image-"] {
  max-width: 100%;
  height: auto;
  margin: 0; }

.video-fluid {
  max-width: 100%;
  height: auto;
  margin: 0; }

.breadcrumbs {
  display: block;
  color: #e4032e;
  padding-top: 1rem; }
  @media only screen and (min-width: 1200px) {
    .breadcrumbs {
      display: none; } }

.u-center-text {
  text-align: center !important; }

/* Utilities Margins */
.u-margin-bottom-small {
  margin-bottom: 1.5rem !important; }

.u-margin-bottom-medium {
  margin-bottom: 4rem !important; }

.u-margin-bottom-big {
  margin-bottom: 8rem !important; }

.u-margin-top-small {
  margin-top: 2rem !important; }

.u-margin-top-medium {
  margin-top: 4rem !important; }

.u-margin-top-big {
  margin-top: 8rem !important; }

.u-margin-top-huge {
  margin-top: 10rem !important; }

/* Utilities Paddings */
.u-padding-bottom-small {
  padding-bottom: 1.5rem !important; }

.u-padding-bottom-medium {
  padding-bottom: 4rem !important; }

.u-padding-bottom-big {
  padding-bottom: 8rem !important; }

.u-padding-top-medium {
  padding-top: 4rem !important; }

.u-padding-top-big {
  padding-top: 8rem !important; }

.u-padding-top-huge {
  padding-top: 10rem !important; }

/*--------------------------------------------------------------
Img
--------------------------------------------------------------*/
.img-fluid {
  width: 100%;
  height: auto; }

/*--------------------------------------------------------------
Figures
--------------------------------------------------------------*/
figure {
  margin: 0 auto;
  width: 100%; }
  figure img {
    width: 100%;
    height: auto; }

/* =WordPress Core
-------------------------------------------------------------- */
.alignnone {
  margin: 5px 20px 20px 0; }

.aligncenter,
div.aligncenter {
  display: block;
  margin: 5px auto 5px auto; }

.alignright {
  float: right;
  margin: 5px 0 20px 20px; }

.alignleft {
  float: left;
  margin: 5px 20px 20px 0; }

a img.alignright {
  float: right;
  margin: 5px 0 20px 20px; }

a img.alignnone {
  margin: 5px 20px 20px 0; }

a img.alignleft {
  float: left;
  margin: 5px 20px 20px 0; }

a img.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto; }

.wp-caption {
  background: #fff;
  border: 1px solid #f0f0f0;
  max-width: 96%;
  /* Image does not overflow the content area */
  padding: 5px 3px 10px;
  text-align: center; }

.wp-caption.alignnone {
  margin: 5px 20px 20px 0; }

.wp-caption.alignleft {
  margin: 5px 20px 20px 0; }

.wp-caption.alignright {
  margin: 5px 0 20px 20px; }

.wp-caption img {
  border: 0 none;
  height: auto;
  margin: 0;
  max-width: 98.5%;
  padding: 0;
  width: auto; }

.wp-caption p.wp-caption-text {
  font-size: 11px;
  line-height: 17px;
  margin: 0;
  padding: 0 4px 5px; }

/* Text meant only for screen readers. */
.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
  white-space: nowrap;
  height: 1px;
  width: 1px;
  overflow: hidden; }

.screen-reader-text:focus {
  background-color: #f1f1f1;
  border-radius: 3px;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
  clip: auto !important;
  color: #21759b;
  display: block;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: bold;
  height: auto;
  left: 5px;
  line-height: normal;
  padding: 15px 23px 14px;
  text-decoration: none;
  top: 5px;
  width: auto;
  z-index: 100000;
  /* Above WP toolbar. */ }

blockquote {
  font-family: Georgia, serif;
  font-style: italic;
  margin: 0.25em 0;
  line-height: 1.45;
  position: relative;
  color: #DA000F;
  width: 50%; }

/*
    Flaticon icon font: Flaticon
    Creation date: 07/03/2017 16:48
    */
@font-face {
  font-family: "Flaticon";
  src: url("./assets/icon_fonts/Flaticon.eot");
  src: url("./assets/icon_fonts/Flaticon.eot?#iefix") format("embedded-opentype"), url("./assets/icon_fonts/Flaticon.woff") format("woff"), url("./assets/icon_fonts/Flaticon.ttf") format("truetype"), url("./assets/icon_fonts/Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal; }

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-family: "Flaticon";
    src: url("./assets/icon_fonts/Flaticon.svg#Flaticon") format("svg"); } }

.fi:before {
  display: inline-block;
  font-family: "Flaticon";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased; }

.flaticon-close:before {
  content: "\f100"; }

.flaticon-menu:before {
  content: "\f101"; }

.wpcf7-list-item {
  margin-left: 0 !important; }

.wpcf7-submit, .wpcf7-submit:link, .wpcf7-submit:visited {
  background: #e4032e;
  border-radius: 6px;
  -webkit-border-radius: 6;
  -moz-border-radius: 6;
  display: inline-block;
  border: none;
  color: #ffffff;
  font-size: 18px;
  padding: 10px 20px 10px 20px;
  text-decoration: none;
  margin-bottom: 30px;
  transition: all .2s; }
  .wpcf7-submit:hover, .wpcf7-submit:link:hover, .wpcf7-submit:visited:hover {
    background: #f0f0f0;
    color: #e4032e;
    transform: translateY(-3px);
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
    text-decoration: none; }
  .wpcf7-submit:active, .wpcf7-submit:focus, .wpcf7-submit:link:active, .wpcf7-submit:link:focus, .wpcf7-submit:visited:active, .wpcf7-submit:visited:focus {
    outline: none;
    transform: translateY(-1px);
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2); }

p small a {
  color: black; }
  p small a:hover {
    color: white; }

.wpcf7 .wpcf7-form .wpcf7-response-output {
  border-color: white !important; }

/* Recatpcha Hide visibility */
.grecaptcha-badge {
  visibility: hidden; }

/* 
.home-hero {
	overflow: hidden;
	position: relative;
	padding-bottom: 56.25%;
	padding-top: 25px;
	height: 0;
	&__wrapper iframe {
		left: 0;
		top: 0;
		height: 100%;
		width: 100%;
		position: absolute;
	}
	.arrow-down {
		display: block;
		position: absolute;
		left: 50%;
		top: 90%;
		transform: translate(-50%, -50%) rotate(180deg);
		cursor: pointer;
		color: white;
		svg {
			height: 32px;
			transition: all .2s;
			@media screen and (min-width: $xsmall) {
				height: 42px;
			}
			.arrow-cls-1 {
				fill: none;
			}
			.arrow-cls-2 {
			fill: $lightestGray;
			}
			&:hover {
				transform: translateY(-10px);
				.arrow-cls-2 {
					fill: $marzoNew;
				}
			}
		}
	}
}

.header-home {
	height: calc(100vh - 10px);
	position: relative;
	z-index: 10;
	&__big-logo{
		margin: 0 auto;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 650px;
		@media only screen and (max-width : 700px) {
			width: 400px;
		}
		@media only screen and (max-width : 500px) {
			width: 300px;
		}
	}
	.arrow-down {
		position: absolute;
		left: 50%;
		top: 80%;
		transform: translate(-50%, -50%) rotate(180deg);
		cursor: pointer;
		color: white;
		svg {
			height: 32px;
			transition: all .2s;
			@media screen and (min-width: $xsmall) {
				height: 42px;
			}
			.arrow-cls-1 {
				fill: none;
			}
			.arrow-cls-2 {
			fill: $marzoNew;
			}
			&:hover {
				transform: translateY(-10px);
			}
		}
	}
} */
.button, .button:link, .button:visited {
  background: #e4032e;
  border-radius: 6px;
  -webkit-border-radius: 6;
  -moz-border-radius: 6;
  display: inline-block;
  border: none;
  color: #ffffff;
  font-size: 18px;
  padding: 10px 20px 10px 20px;
  text-decoration: none;
  margin-bottom: 30px;
  transition: all .2s; }
  .button:hover, .button:link:hover, .button:visited:hover {
    background: #f0f0f0;
    color: #e4032e;
    transform: translateY(-3px);
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
    text-decoration: none; }
  .button:active, .button:focus, .button:link:active, .button:link:focus, .button:visited:active, .button:visited:focus {
    outline: none;
    transform: translateY(-1px);
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2); }

.bokeh {
  margin-top: 6.25rem;
  padding: 50px 0;
  background-image: url(assets/img/bokeh.jpg);
  background-position: center; }
  @media only screen and (max-width: 640px) {
    .bokeh {
      padding: 15px 0; } }

.marzo_square {
  background-color: white;
  display: inline-block;
  width: 30px;
  height: 20px;
  border: 3px solid #DA000F; }

.separator {
  height: 10px;
  display: block;
  width: 100%;
  background-color: #F3F3EC; }

.separator.red {
  background-color: #DA000F; }

.line-separator {
  border: 0.5px solid #e4032e; }

#recent-posts-2 {
  list-style: none;
  height: 100%;
  width: 22em; }

.widgettitle {
  margin: .2em 0; }

#recent-posts-2 ul {
  height: 16em;
  width: 20em;
  margin: .5em 0;
  padding: .5em 0.5em;
  list-style: none; }

#recent-posts-2 li {
  padding: 0.5em 0;
  border-top: .02em solid #B0B0B0; }

#recent-posts-2 li a {
  display: block;
  text-decoration: none;
  color: black; }

#recent-posts-2 li:first-child {
  border-top: none; }

#recent-posts-2 li a:hover {
  color: #DA000F; }

@keyframes bleed {
  0% {
    opacity: 0; }
  60% {
    opacity: 0; }
  61% {
    opacity: 1; }
  100% {
    opacity: 1; } }

@keyframes bleed2 {
  0% {
    opacity: 0; }
  0.1% {
    opacity: 1; }
  14% {
    opacity: 1; }
  13.9% {
    opacity: 1; }
  14% {
    opacity: 0; }
  100% {
    opacity: 0; } }

div.marzo-animated-carousel {
  font-weight: lighter;
  font-style: normal;
  width: 100%;
  height: 906pxBaskervville;
  position: relative; }
  div.marzo-animated-carousel .marzo-animated-carousel-animation h3 {
    opacity: 0;
    animation-name: bleed;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-direction: alternate; }
  div.marzo-animated-carousel h3 {
    color: #e4032e;
    top: 0;
    width: 100%;
    text-align: center;
    margin-top: 201px;
    font-size: 100px;
    position: absolute;
    text-transform: uppercase; }
    div.marzo-animated-carousel h3.pub-car-marz {
      margin-top: 81px;
      font-size: 265px;
      animation-delay: 1.6s; }
      @media only screen and (max-width: 1706px) {
        div.marzo-animated-carousel h3.pub-car-marz {
          margin-top: 90px;
          font-size: 110px; } }
      @media only screen and (max-width: 1688px) {
        div.marzo-animated-carousel h3.pub-car-marz {
          margin-top: 151px;
          font-size: 169px; } }
      @media only screen and (max-width: 1336px) {
        div.marzo-animated-carousel h3.pub-car-marz {
          margin-top: 81px;
          font-size: 175px; } }
      @media only screen and (max-width: 1200px) {
        div.marzo-animated-carousel h3.pub-car-marz {
          margin-top: 90px;
          font-size: 110px; } }
      @media only screen and (max-width: 768px) {
        div.marzo-animated-carousel h3.pub-car-marz {
          margin-top: 80px;
          font-size: 70px; } }
      @media only screen and (max-width: 480px) {
        div.marzo-animated-carousel h3.pub-car-marz {
          margin-top: 50px;
          font-size: 40px; } }
    div.marzo-animated-carousel h3.brand-car-marz {
      margin-top: 70px;
      font-size: 310px;
      animation-delay: 1.65s; }
      @media only screen and (max-width: 1706px) {
        div.marzo-animated-carousel h3.brand-car-marz {
          margin-top: 75px;
          font-size: 140px; } }
      @media only screen and (max-width: 1688px) {
        div.marzo-animated-carousel h3.brand-car-marz {
          margin-top: 111px;
          font-size: 227px; } }
      @media only screen and (max-width: 1336px) {
        div.marzo-animated-carousel h3.brand-car-marz {
          margin-top: 70px;
          font-size: 203px; } }
      @media only screen and (max-width: 1200px) {
        div.marzo-animated-carousel h3.brand-car-marz {
          margin-top: 75px;
          font-size: 140px; } }
      @media only screen and (max-width: 768px) {
        div.marzo-animated-carousel h3.brand-car-marz {
          margin-top: 70px;
          font-size: 90px; } }
      @media only screen and (max-width: 480px) {
        div.marzo-animated-carousel h3.brand-car-marz {
          margin-top: 48px;
          font-size: 45px; } }
    div.marzo-animated-carousel h3.dis-car-marz {
      margin-top: 0;
      font-size: 426px;
      animation-delay: 1.70s; }
      @media only screen and (max-width: 1706px) {
        div.marzo-animated-carousel h3.dis-car-marz {
          margin-top: 38px;
          font-size: 200px; } }
      @media only screen and (max-width: 1688px) {
        div.marzo-animated-carousel h3.dis-car-marz {
          margin-top: 38px;
          font-size: 333px; } }
      @media only screen and (max-width: 1336px) {
        div.marzo-animated-carousel h3.dis-car-marz {
          margin-top: 0px;
          font-size: 290px; } }
      @media only screen and (max-width: 1200px) {
        div.marzo-animated-carousel h3.dis-car-marz {
          margin-top: 38px;
          font-size: 200px; } }
      @media only screen and (max-width: 768px) {
        div.marzo-animated-carousel h3.dis-car-marz {
          margin-top: 50px;
          font-size: 120px; } }
      @media only screen and (max-width: 480px) {
        div.marzo-animated-carousel h3.dis-car-marz {
          margin-top: 35px;
          font-size: 65px; } }
    div.marzo-animated-carousel h3.social-car-marz {
      margin-top: 140px;
      font-size: 185px;
      animation-delay: 1.75s; }
      @media only screen and (max-width: 1706px) {
        div.marzo-animated-carousel h3.social-car-marz {
          margin-top: 105px;
          font-size: 85px; } }
      @media only screen and (max-width: 1688px) {
        div.marzo-animated-carousel h3.social-car-marz {
          margin-top: 185px;
          font-size: 118px; } }
      @media only screen and (max-width: 1336px) {
        div.marzo-animated-carousel h3.social-car-marz {
          margin-top: 140px;
          font-size: 116px; } }
      @media only screen and (max-width: 1200px) {
        div.marzo-animated-carousel h3.social-car-marz {
          margin-top: 105px;
          font-size: 85px; } }
      @media only screen and (max-width: 768px) {
        div.marzo-animated-carousel h3.social-car-marz {
          margin-top: 90px;
          font-size: 53px; } }
      @media only screen and (max-width: 480px) {
        div.marzo-animated-carousel h3.social-car-marz {
          margin-top: 60px;
          font-size: 29px; } }
    div.marzo-animated-carousel h3.events-car-marz {
      margin-top: 50px;
      font-size: 344px;
      animation-delay: 1.8s; }
      @media only screen and (max-width: 1706px) {
        div.marzo-animated-carousel h3.events-car-marz {
          margin-top: 63px;
          font-size: 160px; } }
      @media only screen and (max-width: 1688px) {
        div.marzo-animated-carousel h3.events-car-marz {
          margin-top: 128px;
          font-size: 200px; } }
      @media only screen and (max-width: 1336px) {
        div.marzo-animated-carousel h3.events-car-marz {
          margin-top: 50px;
          font-size: 221px; } }
      @media only screen and (max-width: 1200px) {
        div.marzo-animated-carousel h3.events-car-marz {
          margin-top: 63px;
          font-size: 160px; } }
      @media only screen and (max-width: 768px) {
        div.marzo-animated-carousel h3.events-car-marz {
          margin-top: 65px;
          font-size: 100px; } }
      @media only screen and (max-width: 480px) {
        div.marzo-animated-carousel h3.events-car-marz {
          margin-top: 45px;
          font-size: 50px; } }
    div.marzo-animated-carousel h3.develop-car-marz {
      margin-top: 115px;
      font-size: 235px;
      animation-delay: 1.85s; }
      @media only screen and (max-width: 1706px) {
        div.marzo-animated-carousel h3.develop-car-marz {
          margin-top: 95px;
          font-size: 105px; } }
      @media only screen and (max-width: 1688px) {
        div.marzo-animated-carousel h3.develop-car-marz {
          margin-top: 161px;
          font-size: 146px; } }
      @media only screen and (max-width: 1336px) {
        div.marzo-animated-carousel h3.develop-car-marz {
          margin-top: 83px;
          font-size: 158px; } }
      @media only screen and (max-width: 1200px) {
        div.marzo-animated-carousel h3.develop-car-marz {
          margin-top: 95px;
          font-size: 105px; } }
      @media only screen and (max-width: 768px) {
        div.marzo-animated-carousel h3.develop-car-marz {
          margin-top: 80px;
          font-size: 70px; } }
      @media only screen and (max-width: 480px) {
        div.marzo-animated-carousel h3.develop-car-marz {
          margin-top: 55px;
          font-size: 35px; } }
    div.marzo-animated-carousel h3.creativ-car-marz {
      margin-top: 113px;
      font-size: 239px;
      animation-delay: 1.9s; }
      @media only screen and (max-width: 1706px) {
        div.marzo-animated-carousel h3.creativ-car-marz {
          margin-top: 92px;
          font-size: 105px; } }
      @media only screen and (max-width: 1688px) {
        div.marzo-animated-carousel h3.creativ-car-marz {
          margin-top: 178px;
          font-size: 146px; } }
      @media only screen and (max-width: 1336px) {
        div.marzo-animated-carousel h3.creativ-car-marz {
          margin-top: 113px;
          font-size: 147px; } }
      @media only screen and (max-width: 1200px) {
        div.marzo-animated-carousel h3.creativ-car-marz {
          margin-top: 92px;
          font-size: 105px; } }
      @media only screen and (max-width: 768px) {
        div.marzo-animated-carousel h3.creativ-car-marz {
          margin-top: 85px;
          font-size: 65px; } }
      @media only screen and (max-width: 480px) {
        div.marzo-animated-carousel h3.creativ-car-marz {
          margin-top: 55px;
          font-size: 35px; } }
  div.marzo-animated-carousel .marzo-animated-carousel-words h3 {
    opacity: 0;
    animation-name: bleed2;
    animation-duration: 21s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite; }
    div.marzo-animated-carousel .marzo-animated-carousel-words h3.pub-car-marz {
      animation-delay: 0s; }
    div.marzo-animated-carousel .marzo-animated-carousel-words h3.brand-car-marz {
      animation-delay: 3s; }
    div.marzo-animated-carousel .marzo-animated-carousel-words h3.dis-car-marz {
      animation-delay: 6s; }
    div.marzo-animated-carousel .marzo-animated-carousel-words h3.social-car-marz {
      animation-delay: 9s; }
    div.marzo-animated-carousel .marzo-animated-carousel-words h3.events-car-marz {
      animation-delay: 12s; }
    div.marzo-animated-carousel .marzo-animated-carousel-words h3.develop-car-marz {
      animation-delay: 15s; }
    div.marzo-animated-carousel .marzo-animated-carousel-words h3.creativ-car-marz {
      animation-delay: 18s; }

.wrapper {
  position: relative;
  transform: translateY(-50%);
  top: 50%;
  margin: 0 auto;
  width: 75%;
  overflow: hidden; }

.wrapper article {
  position: absolute;
  left: 100%;
  width: 100%;
  font-size: 2em;
  color: black;
  top: 0;
  cursor: pointer;
  opacity: 0;
  transition: left 1.4s, opacity .5s ease; }
  .wrapper article ol {
    font-size: 2.5rem; }
    .wrapper article ol li span {
      background-image: -webkit-gradient(linear, left top, right top, from(#92bf1f), to(#008c36));
      background-image: linear-gradient(to right, #92bf1f, #008c36);
      -webkit-background-clip: text;
      color: transparent;
      text-transform: uppercase; }
  .wrapper article p span {
    background-image: -webkit-gradient(linear, left top, right top, from(#92bf1f), to(#008c36));
    background-image: linear-gradient(to right, #92bf1f, #008c36);
    -webkit-background-clip: text;
    color: transparent; }

.wrapper article.activeText {
  position: relative;
  left: 0;
  opacity: 1; }

.wrapper article.slideLeft {
  left: -100%;
  opacity: 0; }

ul li {
  list-style: none;
  padding: 1rem; }

.dot {
  background-image: linear-gradient(to bottom, red, #e4032e);
  border: none;
  border-radius: 8px;
  color: white;
  cursor: pointer;
  display: inline-block;
  font-family: 'Roboto', sans-serif;
  font-size: 3.5rem;
  font-weight: 700;
  padding: 1rem 3rem;
  margin: 0 1rem;
  text-align: center; }

.active {
  background-color: #c5b59a;
  color: white; }

.header {
  /* Header without video */
  /* Header common pages and Home with video */ }
  .header__home {
    height: calc(100vh - 10px);
    position: relative;
    z-index: 10; }
  .header__big-logo {
    margin: 0 auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 650px;
    /* Small Devices, Tablets */ }
    @media only screen and (max-width: 700px) {
      .header__big-logo {
        width: 400px; } }
    @media only screen and (max-width: 500px) {
      .header__big-logo {
        width: 300px; } }
  .header__arrow-down {
    position: absolute;
    left: 50%;
    top: 90%;
    transform: translate(-50%, -50%) rotate(180deg);
    cursor: pointer;
    color: white; }
    .header__arrow-down svg {
      height: 32px;
      transition: all .2s; }
      @media screen and (min-width: 34em) {
        .header__arrow-down svg {
          height: 42px; } }
      .header__arrow-down svg .arrow-cls-1 {
        fill: none; }
      .header__arrow-down svg .arrow-cls-2 {
        fill: #e4032e; }
      .header__arrow-down svg:hover {
        transform: translateY(-10px); }
  .header__pages {
    height: 12rem; }
  .header__contact {
    display: none; }
    @media only screen and (min-width: 1200px) {
      .header__contact {
        display: flex;
        align-items: center;
        justify-content: flex-end; } }
  .header__small-logo {
    margin-top: 2em; }
  .header__link {
    padding-right: 2rem;
    padding-top: 1rem;
    font-size: 1.5rem; }
    .header__link span {
      color: #e4032e; }
  .header__hero {
    position: relative;
    z-index: 10;
    height: calc(100vh - 130px);
    width: 100vw; }
    @media only screen and (max-width: 640px) {
      .header__hero {
        max-height: 55rem; } }
  .header__video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
    .header__video iframe {
      width: 1140px;
      height: 640px; }
      @media only screen and (max-width: 1200px) {
        .header__video iframe {
          width: 970px;
          height: 552px; } }
      @media only screen and (max-width: 1024px) {
        .header__video iframe {
          width: 750px;
          height: 431px; } }
      @media only screen and (max-width: 768px) {
        .header__video iframe {
          width: 598px;
          height: 331px; } }
      @media only screen and (max-width: 640px) {
        .header__video iframe {
          width: 400px;
          height: 200px; } }
      @media only screen and (max-width: 34em) {
        .header__video iframe {
          width: 365px; } }

.main-menu {
  display: flex;
  margin: 0;
  padding: 2em 0;
  justify-content: center;
  align-items: center;
  z-index: 30; }
  .main-menu li {
    list-style: none;
    border-right: 1px solid #B0B0B0; }
    .main-menu li:last-of-type {
      border-right: none; }
    .main-menu li a {
      padding: 0 10px;
      color: #000;
      text-decoration: none; }
      .main-menu li a:hover {
        color: #e4032e; }

.flaticon-menu {
  cursor: pointer;
  font-size: 2em;
  float: right;
  padding-top: 29px; }

.flaticon-close {
  background-color: #DA000F;
  border-radius: 50%;
  color: white;
  cursor: pointer;
  font-size: 2em;
  margin-top: 17px;
  padding: 3px 9px 1px;
  position: fixed;
  top: 15px;
  transition: all .3s ease-in-out;
  right: -400px; }

.current-menu-item a {
  color: #DA000F !important; }

/* Small Devices, Tablets */
@media only screen and (max-width: 1200px) {
  .main-menu {
    display: inline-block;
    width: 230px;
    box-shadow: 0 -4px 20px rgba(0, 0, 0, 0.8);
    margin: 0;
    z-index: 20;
    right: -400px;
    transition: all .3s ease-in-out;
    top: 0;
    height: 100%;
    position: fixed;
    background-color: #DA000F; }
    .main-menu li {
      display: block;
      padding: 10px; }
      .main-menu li a {
        color: white; }
        .main-menu li a:hover {
          color: black; }
    .main-menu .current-menu-item a {
      color: black !important; } }

.category {
  background-color: #F3F3EC; }

.category article a {
  text-decoration: none;
  color: #DA000F; }
  .category article a:hover {
    color: black; }

.category article h1 {
  font-weight: 100; }
  .category article h1 a {
    color: black;
    text-decoration: none; }
    .category article h1 a:hover {
      color: #DA000F; }

.category article aside {
  display: flex;
  align-items: center; }
  .category article aside i {
    margin-right: 3px; }

.footer {
  background-color: #e4032e;
  height: 22rem;
  width: 100vw;
  font-size: .8em;
  padding-top: 5rem;
  position: relative;
  color: white;
  /*   padding: 5rem 0 1rem; */ }
  @media screen and (min-width: 34em) {
    .footer {
      height: 35rem; } }
  .footer__logo {
    width: 150px; }
    @media only screen and (min-width: 640px) {
      .footer__logo {
        margin-bottom: 10px;
        width: 250px; } }
    .footer__logo svg.white-cls-1 {
      fill: white; }
  .footer__box-one, .footer__box-two, .footer__contact {
    align-items: center;
    display: flex;
    height: 3rem;
    justify-content: center; }
  .footer__services-item ul {
    display: flex;
    font-size: 1rem;
    justify-content: center;
    list-style: none;
    padding: 0;
    margin: 2rem 0; }
    @media screen and (min-width: 34em) {
      .footer__services-item ul {
        font-size: inherit; } }
    .footer__services-item ul li {
      padding: 3px; }
      @media screen and (min-width: 34em) {
        .footer__services-item ul li {
          padding: 5px; } }
  .footer__link {
    color: inherit;
    padding-right: 2rem;
    font-size: 1.5rem; }
    .footer__link span {
      color: inherit; }
    .footer__link:hover {
      color: black; }
  .footer__social nav {
    display: flex;
    justify-content: center;
    align-items: center; }
  .footer__social-item {
    padding: 0; }
    .footer__social-item a svg {
      height: 30px;
      transition: all .2s; }
      @media screen and (min-width: 34em) {
        .footer__social-item a svg {
          height: 42px; } }
      .footer__social-item a svg .cls-1 {
        fill: #e4032e; }
      .footer__social-item a svg .cls-2 {
        fill: white; }
      .footer__social-item a svg:hover {
        padding: 0 .8rem;
        transform: translateY(-2px); }
        .footer__social-item a svg:hover .cls-1 {
          fill: #f0f0f0; }
        .footer__social-item a svg:hover .cls-2 {
          fill: #e4032e; }
  .footer__copy {
    font-size: 1rem;
    margin: 0.7rem; }
    @media screen and (min-width: 34em) {
      .footer__copy {
        font-size: inherit; } }
  .footer__menu {
    padding: 0 0; }
  .footer__list {
    display: flex;
    font-size: 1rem;
    list-style: none;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0; }
    @media screen and (min-width: 34em) {
      .footer__list {
        font-size: inherit; } }
    .footer__list li {
      border-right: 1px solid #f0f0f0; }
      .footer__list li:last-child {
        border: none; }
      .footer__list li a {
        padding: 0 2px;
        color: white;
        cursor: pointer;
        text-decoration: none; }
        .footer__list li a:hover {
          color: black; }
    .footer__list .current-menu-item a {
      color: black !important; }
  .footer #legal ul li {
    border-right: none; }
    .footer #legal ul li:last-child {
      border: none; }
  .footer__arrow_up {
    position: absolute;
    right: 3px;
    bottom: 20px;
    cursor: pointer; }
    @media screen and (min-width: 560px) {
      .footer__arrow_up {
        right: 100px;
        bottom: 100px; } }
    .footer__arrow_up svg {
      height: 30px;
      transition: all .2s; }
      @media screen and (min-width: 34em) {
        .footer__arrow_up svg {
          height: 42px; } }
      .footer__arrow_up svg:hover {
        transform: translateY(-5px); }
        .footer__arrow_up svg:hover .arrow-cls-1 {
          fill: #f0f0f0; }
        .footer__arrow_up svg:hover .arrow-cls-2 {
          fill: #e4032e; }

/* .page-title, 
.theme-page__tittle,
.single-article__header {
  background-color: $marzoRed;
  background-image: url(assets/img/acuarela_loop.jpg);
  background-size: cover;
  background-repeat: repeat;
  color:white;
  font-size: .8em;
  height: 20rem;
  padding: 45px 0 10px;
  margin-top: 3em;
  h1 {
    font-size: 4rem;
    padding: 1rem 15px;
  }
} */
#breadcrumbs {
  font-size: 1.5rem;
  margin: 0;
  padding-top: 20px; }
  #breadcrumbs a {
    color: black; }
    #breadcrumbs a:hover {
      color: #e4032e; }

.primary-part {
  /*     @media screen and (min-height: 640px) and (orientation: portrait) { 
        ...
    } */ }
  @media only screen and (min-width: 1400px) {
    .primary-part {
      /* height: calc(100vh - 130px); */
      width: 100vw; } }
  @media only screen and (min-width: 1200px) {
    .primary-part__quienes, .primary-part__contacto-page {
      margin-top: 5rem; } }

.blog-header {
  height: 350px;
  background: url(assets/img/blog_marzo_640.jpg) center center;
  background-size: cover;
  padding: 50px 0; }
  .blog-header h1 {
    font-weight: 100;
    font-size: 8rem; }
  @media screen and (min-width: 640px) {
    .blog-header {
      background: url(assets/img/blog_marzo_1024.jpg) center center; } }
  @media screen and (min-width: 768px) {
    .blog-header {
      background: url(assets/img/blog_marzo.jpg) center center; } }

.blog-marzo {
  /* 	background: url(assets/img/quienes_somos_dos.jpg);
	background-position: center;
	background-size: cover; */
  background-color: #F3F3EC; }
  .blog-marzo__content {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 1rem;
    padding: 5rem 1rem; }
    @media screen and (min-width: 640px) {
      .blog-marzo__content {
        padding: 5rem;
        grid-gap: 2rem; } }
    @media screen and (min-width: 1024px) {
      .blog-marzo__content {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 2.5rem; } }
    @media screen and (min-width: 1200px) {
      .blog-marzo__content {
        grid-template-columns: repeat(3, 1fr); } }
    @media screen and (min-width: 1400px) {
      .blog-marzo__content {
        grid-gap: 5rem;
        padding: 20rem; } }
  .blog-marzo__item {
    background-color: #f0f0f0;
    border: 20px solid #e4032e; }
    .blog-marzo__item, .blog-marzo__item:link, .blog-marzo__item:visited {
      display: inline-block;
      transition: all .2s;
      outline: none; }
      .blog-marzo__item p, .blog-marzo__item:link p, .blog-marzo__item:visited p {
        color: black;
        font-size: 1.2rem; }
        @media screen and (min-width: 768px) {
          .blog-marzo__item p, .blog-marzo__item:link p, .blog-marzo__item:visited p {
            font-size: 1.6rem; } }
      .blog-marzo__item:hover, .blog-marzo__item:link:hover, .blog-marzo__item:visited:hover {
        transform: translateY(-3px);
        box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
        text-decoration: none; }
        .blog-marzo__item:hover p, .blog-marzo__item:link:hover p, .blog-marzo__item:visited:hover p {
          color: black; }
      .blog-marzo__item:active, .blog-marzo__item:focus, .blog-marzo__item:link:active, .blog-marzo__item:link:focus, .blog-marzo__item:visited:active, .blog-marzo__item:visited:focus {
        outline: none;
        transform: translateY(-1px);
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2); }
    .blog-marzo__item h1 {
      color: #e4032e;
      font-size: 1.6rem;
      font-family: 'theano_didotregular'; }
      @media screen and (min-width: 768px) {
        .blog-marzo__item h1 {
          font-size: 2rem; } }
  .blog-marzo__img img {
    height: auto;
    width: 100%; }
  .blog-marzo__text {
    padding: 0 10px; }
  .blog-marzo__icon {
    display: flex;
    align-items: center; }
    .blog-marzo__icon span {
      font-size: 1.2rem;
      padding-left: 5px;
      margin: 5px 0; }
      @media screen and (min-width: 640px) {
        .blog-marzo__icon span {
          font-size: 1.5rem; } }
  .blog-marzo__next-posts a, .blog-marzo__next-posts a:link, .blog-marzo__next-posts a:visited, .blog-marzo__prev-posts a, .blog-marzo__prev-posts a:link, .blog-marzo__prev-posts a:visited {
    border-radius: 3px;
    display: inline-block;
    background: #f0f0f0;
    border: none;
    font-size: 18px;
    padding: 10px 20px 10px 20px;
    text-decoration: none;
    margin-bottom: 30px;
    transition: all .2s; }
    .blog-marzo__next-posts a:hover, .blog-marzo__next-posts a:link:hover, .blog-marzo__next-posts a:visited:hover, .blog-marzo__prev-posts a:hover, .blog-marzo__prev-posts a:link:hover, .blog-marzo__prev-posts a:visited:hover {
      color: #e4032e;
      transform: translateY(-3px);
      box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
      text-decoration: none; }
    .blog-marzo__next-posts a:active, .blog-marzo__next-posts a:focus, .blog-marzo__next-posts a:link:active, .blog-marzo__next-posts a:link:focus, .blog-marzo__next-posts a:visited:active, .blog-marzo__next-posts a:visited:focus, .blog-marzo__prev-posts a:active, .blog-marzo__prev-posts a:focus, .blog-marzo__prev-posts a:link:active, .blog-marzo__prev-posts a:link:focus, .blog-marzo__prev-posts a:visited:active, .blog-marzo__prev-posts a:visited:focus {
      outline: none;
      transform: translateY(-1px);
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2); }

.service-article:nth-child(odd) {
  background-color: #f0f0f0; }

.service-article:nth-child(even) {
  background-color: white; }

.service-article {
  padding: 20px 0; }
  @media only screen and (min-width: 1024px) {
    .service-article {
      padding: 70px 0; } }

.service-article-title {
  font-size: .8em;
  font-weight: 100; }

.service-article-title:before {
  content: "#";
  color: #e4032e; }

.heading-primary-servicios {
  font-family: 'theano_didotregular'; }

.whatsabble__header {
  background: url(assets/img/whatsabble_img_bn.jpg) center center;
  background-size: cover;
  background-repeat: repeat;
  height: calc(100vh - 10rem);
  width: 100vw;
  margin-top: 1.875rem;
  position: relative; }

.whatsabble__sello {
  height: 25rem;
  width: auto;
  position: absolute;
  top: 16%;
  right: 0%;
  transform: translate(-50%, -50%); }

.whatsabble__claim-one, .whatsabble__claim-two, .whatsabble__claim-three {
  background-color: rgba(228, 3, 44, 0.355);
  display: flex;
  align-items: center;
  width: 100%;
  position: absolute;
  top: 91%;
  left: 50%;
  transform: translate(-50%, -50%); }

.whatsabble__claim-one {
  height: 11rem;
  z-index: 30; }

.whatsabble__claim-two {
  height: 15rem;
  z-index: 20; }

.whatsabble__claim-three {
  height: 20rem;
  z-index: 10; }

.whatsabble__logo {
  height: 7rem;
  width: auto;
  margin-left: 11rem;
  margin-right: 5rem; }

.whatsabble__hero-text {
  color: white;
  font-size: 1.2em;
  font-family: 'Roboto', sans-serif;
  font-weight: 400; }

.whatsabble__description {
  background-color: #e4032e;
  text-align: center;
  padding: 10rem 0; }

.whatsabble__description-container {
  border: 2px solid #fff;
  border-radius: 53px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50rem; }

.whatsabble__description-claim {
  color: white;
  font-size: 1.7em;
  font-family: 'Roboto', sans-serif;
  font-weight: 400; }

.whatsabble__description-finger {
  height: 7rem;
  padding-right: 2rem;
  width: auto; }

.whatsabble__description-text {
  text-align: center;
  margin-top: 2rem; }
  .whatsabble__description-text p {
    color: white;
    margin: .4rem 0; }

.whatsabble__game {
  background-color: #f0f0f0;
  display: flex;
  height: auto;
  margin: 0; }

.whatsabble__how-to, .whatsabble__filosofia, .whatsabble__funcionamiento {
  padding: 7rem;
  /* text-align: center; */
  width: 33.333333%; }
  .whatsabble__how-to p, .whatsabble__filosofia p, .whatsabble__funcionamiento p {
    margin: 1rem 0;
    font-size: 2rem; }

.whatsabble__button {
  background-image: linear-gradient(to bottom, red, #e4032e);
  border: none;
  border-radius: 8px;
  color: white;
  font-family: 'Roboto', sans-serif;
  font-size: 3.5rem;
  font-weight: 700;
  outline: none;
  padding: 1rem 3rem;
  text-transform: uppercase; }
  .whatsabble__button:active, .whatsabble__button:hover {
    background-color: #c5b59a; }

.whatsabble__article p {
  margin: 1rem 0; }

.whatsabble__list {
  padding: 0;
  list-style: none;
  text-align: inherit; }

.whatsabble__list-items {
  list-style: none;
  background-image: -webkit-gradient(linear, left top, right top, from(#92bf1f), to(#008c36));
  background-image: linear-gradient(to right, #92bf1f, #008c36);
  -webkit-background-clip: text;
  color: transparent;
  font-size: 2rem; }

.whatsabble__words {
  height: 110rem;
  margin: 5rem 0 0 0;
  padding: 5rem 0; }

.whatsabble__footer {
  text-align: center; }

.whatsabble__footer-logo {
  height: 7rem;
  margin-top: 10rem;
  width: auto; }

.whatsabble__hastag {
  margin-bottom: 6rem;
  background-image: -webkit-gradient(linear, left top, right top, from(#92bf1f), to(#008c36));
  background-image: linear-gradient(to right, #92bf1f, #008c36);
  -webkit-background-clip: text;
  color: transparent;
  font-family: 'Roboto', sans-serif;
  font-size: 7rem;
  font-weight: 400; }

.whatsabble__iniciativa {
  background-color: #e4032e;
  height: 15rem;
  padding-top: 5rem;
  text-align: center; }
  .whatsabble__iniciativa p {
    color: white;
    font-size: 2rem;
    margin: .4rem 0; }

#panel-one,
#panel-two,
#panel-three {
  display: none; }

.contacto_content a {
  color: #DA000F; }
  .contacto_content a:hover {
    color: black; }

/* .page-template-page-contacto {
	main  {
		@media only screen and (max-width: $tiny) {
			padding-bottom: 10rem;
		  }
	}
} */
.single-article {
  padding: 5rem 0; }
  @media screen and (min-width: 768px) {
    .single-article {
      padding: 5rem 0 2rem 0; } }
  .single-article__header {
    margin-top: 5rem; }
    @media screen and (min-width: 768px) {
      .single-article__header {
        margin-top: 10rem; } }
    .single-article__header h1 {
      font-size: 1.8rem; }
      @media screen and (min-width: 640px) {
        .single-article__header h1 {
          font-size: 2rem; } }
      @media screen and (min-width: 768px) {
        .single-article__header h1 {
          font-size: 3rem; } }
      @media screen and (min-width: 1200px) {
        .single-article__header h1 {
          font-size: 3.5rem; } }
  .single-article__aside {
    font-size: inherit;
    display: flex;
    align-items: center;
    margin: 10px 0; }
    .single-article__aside i {
      margin-right: 5px; }
    .single-article__aside span {
      display: none; }
      @media screen and (min-width: 640px) {
        .single-article__aside span {
          display: block;
          margin-left: 5px; } }
  .single-article__social {
    display: flex;
    align-items: center; }
    .single-article__social span {
      color: #DA000F;
      cursor: pointer;
      margin-left: 10px;
      transition: all .2s; }
      .single-article__social span:hover {
        transform: translateX(4px); }
  @media screen and (min-width: 640px) {
    .single-article__content {
      text-align: justify; } }
  @media screen and (min-width: 640px) and (min-width: 640px) {
    .single-article__content--cat {
      display: none; } }
  .single-article__video {
    position: relative;
    padding-bottom: 56.25%;
    /*panorámico*/
    padding-top: 25px;
    height: 0; }
  .single-article__video-element iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.continuar {
  margin: 3rem 0 6rem; }
  .continuar p span {
    color: #DA000F;
    cursor: pointer;
    display: inline-block;
    margin-left: 10px;
    transition: all .2s; }
    .continuar p span:hover {
      transform: rotate(90deg); }
  .continuar ul {
    list-style: none;
    padding: 0; }
  .continuar li a, .continuar li a:link, .continuar li a:visited {
    border-radius: 3px;
    display: inline-block;
    background: #f0f0f0;
    border: none;
    font-size: 18px;
    padding: 10px 20px 10px 20px;
    text-decoration: none;
    margin-bottom: 10px;
    transition: all .2s; }
    .continuar li a:hover, .continuar li a:link:hover, .continuar li a:visited:hover {
      color: #e4032e;
      transform: translateY(-3px);
      box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
      text-decoration: none; }
    .continuar li a:active, .continuar li a:focus, .continuar li a:link:active, .continuar li a:link:focus, .continuar li a:visited:active, .continuar li a:visited:focus {
      outline: none;
      transform: translateY(-1px);
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2); }

.wp_rp_content ul li {
  list-style: none; }

.wp_rp_content li a.wp_rp_title {
  text-decoration: none;
  color: black; }
  .wp_rp_content li a.wp_rp_title:hover {
    color: #DA000F; }

div.social-sharing ul {
  list-style: none;
  display: inline-flex;
  padding: 0; }
  div.social-sharing ul li a {
    padding-left: 1rem; }
    div.social-sharing ul li a i {
      color: #B0B0B0;
      font-size: 25px;
      padding: 3px;
      transition: all .2s; }
      div.social-sharing ul li a i:hover {
        color: #e4032e;
        transform: translateY(-2px); }

@media only screen and (min-width: 768px) {
  div.social-sharing a.dc-whatsapp {
    display: none; } }

.contacto {
  background-color: #e4032e;
  color: white; }
  .contacto header {
    font-size: 1.3rem; }
    @media screen and (min-width: 768px) {
      .contacto header {
        font-size: inherit; } }
  .contacto__tittle {
    margin: 8rem 0 1rem 0;
    color: white; }
  .contacto__data {
    color: inherit;
    margin: 0px;
    padding-bottom: .5rem; }
  .contacto__telefono {
    color: white;
    text-decoration: none; }
    .contacto__telefono:hover {
      color: black; }
  .contacto__fields {
    color: white; }
  .contacto__address {
    margin-bottom: 8rem; }

.quienes-somos {
  background: url(assets/img/quienes_somos_tres.jpg) center center;
  background-size: cover;
  background-repeat: repeat;
  height: calc(100vh - 10px);
  width: 100vw;
  position: relative; }
  .quienes-somos__text {
    padding: 1rem;
    color: #e4032e;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
    @media only screen and (max-width: 68.125em) {
      .quienes-somos__text {
        background-color: #f0f0f0;
        width: 53%; } }
    @media only screen and (max-width: 59.8125em) {
      .quienes-somos__text {
        width: 65%; } }
    @media only screen and (max-width: 768px) {
      .quienes-somos__text {
        width: 75%; } }
    @media only screen and (max-width: 42.75em) {
      .quienes-somos__text {
        width: 62%;
        box-sizing: content-box; } }
    @media only screen and (max-width: 640px) {
      .quienes-somos__text {
        width: 90%; } }
    .quienes-somos__text h2 {
      font-family: 'theano_didotregular';
      font-size: 3.2rem;
      margin: 20px 0; }
      @media only screen and (max-width: 106.25em) {
        .quienes-somos__text h2 {
          font-size: 3rem; } }
      @media only screen and (max-width: 100em) {
        .quienes-somos__text h2 {
          font-size: 2.5rem; } }
      @media only screen and (max-width: 83.31em) {
        .quienes-somos__text h2 {
          font-size: 2rem; } }
      @media only screen and (max-width: 68.125em) {
        .quienes-somos__text h2 {
          font-size: 1.9rem;
          margin: 13px 0; } }
      @media only screen and (max-width: 42.75em) {
        .quienes-somos__text h2 {
          font-size: 1.5rem; } }
      @media only screen and (max-width: 640px) {
        .quienes-somos__text h2 {
          font-size: 1.7rem;
          margin: 6px 0; } }
      .quienes-somos__text h2 span {
        background-color: #f0f0f0;
        padding: 10px 15px; }
        @media only screen and (max-width: 640px) {
          .quienes-somos__text h2 span {
            background-color: transparent;
            padding: 10px 2px; } }

ul.related_post {
  width: 100%;
  list-style: none;
  padding: 0;
  margin: 0; }

ul.related_post li {
  width: 25% !important;
  height: auto;
  float: left;
  padding: 10px; }

ul.related_post li a {
  text-decoration: none; }

ul.related_post li img {
  width: 100%;
  height: auto; }

.wp_rp_content {
  display: block !important;
  overflow: hidden; }

.wp_rp_footer {
  display: none; }

.services-list {
  position: relative;
  height: 35rem;
  width: 100vw;
  text-align: center; }
  @media screen and (min-width: 768px) {
    .services-list {
      height: 42rem; } }
  @media screen and (min-width: 1200px) {
    .services-list {
      height: 75rem; } }
  @media screen and (min-width: 1400px) {
    .services-list {
      height: calc(100vh - 20px); } }
  .services-list__effect {
    position: absolute;
    width: 100%;
    top: 42%;
    left: 50%;
    transform: translate(-50%, -50%); }

.logo_marcas {
  min-height: 120px;
  display: flex;
  align-items: center;
  justify-content: center; }
  .logo_marcas .logo_img {
    height: 65px; }
  .logo_marcas .logo_new {
    height: 100px; }
  @media only screen and (min-width: 640px) {
    .logo_marcas {
      min-height: 150px; } }

/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on October 20, 2018 */
@font-face {
  font-family: 'theano_didotregular';
  src: url("assets/fonts/theanodidot-regular-webfont.eot");
  src: url("assets/fonts/theanodidot-regular-webfont.eot?#iefix") format("embedded-opentype"), url("assets/fonts/theanodidot-regular-webfont.woff2") format("woff2"), url("assets/fonts/theanodidot-regular-webfont.woff") format("woff"), url("assets/fonts/theanodidot-regular-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

.heading-primary span {
  margin: 1rem 0; }

.heading-primary__color {
  color: #e4032e; }
