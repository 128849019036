/*!
Theme Name: Theme Name: Marzo, Agencia de Publicidad y Marketing
Author: Marzo Marketing
Author URI: https://marzo.com.es
*/
@import "abstracts/mixins";
@import "abstracts/variables";
@import "base/bootstrap";
@import "base/common";
@import "base/utilities";
@import "vendors/wordpress_core";
@import "vendors/flaticon";
@import "vendors/contact_form";
@import "components/bg_video";
@import "components/buttons";
@import "components/bokeh";
@import "components/marzo_square";
@import "components/separator";
@import "components/sidebar";
@import "components/words_effect";
@import "components/whatsabble_carrusell";
@import "layout/header";
@import "layout/category";
@import "layout/footer";
@import "layout/page-header";
@import "pages/page_common";
@import "pages/blog";
@import "pages/page_servicios";
@import "pages/page_whatsabble";
@import "pages/page_contacto";
@import "pages/single";
@import "pages/sections/contacto";
@import "pages/sections/quienes_somos";
@import "pages/sections/related_posts";
@import "pages/sections/service_list";
@import "pages/sections/nuestras_marcas";
@import "typography/typofonts";
@import "typography/typography";













