.quienes-somos {
    background: url(assets/img/quienes_somos_tres.jpg) center center;
    background-size: cover;
    background-repeat: repeat;
    height: calc(100vh - 10px);
    width: 100vw;
    position: relative;
    &__text {
        padding: 1rem;
        color: $marzoNew;
        justify-content: center;
        @include absCenter;
        @media only screen and (max-width: 68.125em) {
            background-color: $lightestGray;
            width: 53%;
        }
        @media only screen and (max-width: 59.8125em) {
            width: 65%;
        }
        @media only screen and (max-width: $small) {
            width: 75%;
        }
        @media only screen and (max-width: 42.75em) {
            width: 62%;
            box-sizing: content-box;
        }
        @media only screen and (max-width: $tiny) {
            width: 90%;
        }
        h2 {
            font-family: 'theano_didotregular';
            font-size: 3.2rem;
            margin: 20px 0;
            @media only screen and (max-width: 106.25em) {
                font-size: 3rem;
            }
            @media only screen and (max-width: 100em) {
                font-size: 2.5rem;
            }
            @media only screen and (max-width: 83.31em) {
                font-size: 2rem;
            }
            @media only screen and (max-width: 68.125em) {
                font-size: 1.9rem;
                margin: 13px 0;
            }
            @media only screen and (max-width: 42.75em) {
                font-size: 1.5rem;
            }
            @media only screen and (max-width: $tiny) {
                font-size: 1.7rem;
                margin: 6px 0;
            }
            span {
                background-color: $lightestGray;
                padding: 10px 15px;
                @media only screen and (max-width: $tiny) {
                    background-color: transparent;
                    padding: 10px 2px;
                }
            }
        }
    }
}

