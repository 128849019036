.service-article:nth-child(odd) {
	background-color: $lightestGray;
}
.service-article:nth-child(even) {
	background-color: white;
}
.service-article{
	padding: 20px 0;
	@media only screen and (min-width: $medium) {	
		padding: 70px 0;
	}
}
.service-article-title{
	font-size:.8em;
	font-weight: 100;
}
.service-article-title:before{
	content : "#";
	color: $marzoNew;
}

.heading-primary-servicios {
	font-family: 'theano_didotregular';
}