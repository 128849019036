//TYPOGRAPHY
/* @mixin main-font ($color: #101921) { 
    color: $color;
    font-family: 'Source Sans Pro', sans-serif;
}

@mixin primary-headers ($bold: bold) { 
    font-family: 'Archivo Narrow', sans-serif;
    font-weight: $bold;
    text-transform: uppercase;
}

@mixin secondary-headers ($color: $torimbia-primary-dark, $bold: bold) { 
    color: $color;
    font-family: 'Archivo Narrow', sans-serif;
    font-weight: $bold;
    text-transform: uppercase;
}

@mixin tertiary-headers ($color: $color-grey-dark, $bold: bold) {
    font-family: 'Archivo Narrow', sans-serif;
    font-weight: $bold;
    color: $color;
} */



@mixin clearfix {
    &::after {
        content: "";
        display: table;
        clear: both;
    }
}

@mixin absCenter {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@mixin absBottom {
    position: absolute;
    top: 64%;
    left: 50%;
    transform: translate(-50%, -50%);
}

/*----------------------
Theme image background mixin
------------------------*/
@mixin imageBackground ($backSize: cover, $bgPos: top center) {
    background-position: $bgPos;
/*     background-repeat: repeat no-repeat; */
    background-size: $backSize;
    height: auto;
    width: 100%;
}
