    /*
    Flaticon icon font: Flaticon
    Creation date: 07/03/2017 16:48
    */

    @font-face {
  font-family: "Flaticon";
  src: url("./assets/icon_fonts/Flaticon.eot");
  src: url("./assets/icon_fonts/Flaticon.eot?#iefix") format("embedded-opentype"),
       url("./assets/icon_fonts/Flaticon.woff") format("woff"),
       url("./assets/icon_fonts/Flaticon.ttf") format("truetype"),
       url("./assets/icon_fonts/Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("./assets/icon_fonts/Flaticon.svg#Flaticon") format("svg");
  }
}

    .fi:before{
        display: inline-block;
  font-family: "Flaticon";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
    }

    .flaticon-close:before { content: "\f100"; }
.flaticon-menu:before { content: "\f101"; }
    
    $font-Flaticon-close: "\f100";
    $font-Flaticon-menu: "\f101";