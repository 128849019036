.header {
	/* Header without video */
	&__home {
		height: calc(100vh - 10px);
		position: relative;
		z-index: 10;
	}	
	&__big-logo {
		margin: 0 auto;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 650px;
		/* Small Devices, Tablets */
		@media only screen and (max-width : 700px) {
			width: 400px;
		}
		@media only screen and (max-width : 500px) {
			width: 300px;
		}
	}
	&__arrow-down {
		position: absolute;
		left: 50%;
		top: 90%;
		transform: translate(-50%, -50%) rotate(180deg);
		cursor: pointer;
		color: white;
		svg {
			height: 32px;
			transition: all .2s;
			@media screen and (min-width: $xsmall) {
				height: 42px;
			}
			.arrow-cls-1 {
				fill: none;
			}
			.arrow-cls-2 {
			fill: $marzoNew;
			}
			&:hover {
				transform: translateY(-10px);
			}
		}		
	}
	/* Header common pages and Home with video */
	&__pages {
		height: 12rem;
	}
	&__contact {
		display: none;
		@media only screen and (min-width: $large) {
			display: flex;
			align-items: center;
			justify-content: flex-end;
		}
	}
	&__small-logo {
		margin-top: 2em;
	}
	&__link {
		padding-right: 2rem;
		padding-top: 1rem;
		font-size: 1.5rem;
		span {
			color: $marzoNew;
		}
	}
	&__hero {
		position: relative;
		z-index: 10;
		height: calc(100vh - 130px);
		width: 100vw;
		@media only screen and (max-width: $tiny) {
			max-height: 55rem;
		}
	}
	&__video {
		@include absCenter;

		iframe {
			width: 1140px;
			height: 640px;
			@media only screen and (max-width: $large) {
				width: 970px;
				height: 552px;
			}
			@media only screen and (max-width: $medium) {
				width: 750px;
				height: 431px;
			}
			@media only screen and (max-width: $small) {
				width: 598px;
				height: 331px;
			}
			@media only screen and (max-width: $tiny) {
				width: 400px;
				height: 200px;
			}
			@media only screen and (max-width: $xsmall) {
				width: 365px;
			}
		} 
	}
}

 
.main-menu {
    display: flex;
    margin: 0;
    padding: 2em 0;
	justify-content: center;
	align-items: center;
	z-index: 30;
	li {
		list-style: none;
		border-right: 1px solid $lightGray;
		&:last-of-type {
			border-right: none;
		}
		a{
			padding: 0 10px;	
			color: #000;
			text-decoration: none;
			&:hover{
				color: $marzoNew;
			}
		}
	}
}
.flaticon-menu {
	cursor: pointer;
	font-size: 2em;
	float: right;
	padding-top: 29px;
}
.flaticon-close {
	background-color: $marzoRed;
	border-radius: 50%;
	color: white;
	cursor: pointer;
	font-size: 2em;
	margin-top: 17px;
	padding: 3px 9px 1px;
	position: fixed;
	top: 15px;
	transition: all .3s ease-in-out;
	right: -400px;
}
.current-menu-item a {
	color: $marzoRed !important;
}
/* Small Devices, Tablets */
@media only screen and (max-width : $large) {
	.main-menu {
		display: inline-block;
		width: 230px;
		box-shadow: 0 -4px 20px rgba(0,0,0,.8);
		margin: 0;
		z-index: 20;
		right: -400px;
		transition: all .3s ease-in-out;
		top: 0;
		height: 100%;
		position: fixed;
		background-color: $marzoRed;
		li {
			display: block;
			padding:10px;
			a {
				color:white;
				&:hover{
					color:black;
				}
			}
		}
		.current-menu-item a {
			color: black !important;
		}
	}
}