
@keyframes bleed {
    0% {
      opacity: 0;
    }
    60% {
      opacity: 0;
    }
    61% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  
  @keyframes bleed2 {
    0% {
      opacity: 0;
    }
    0.1% {opacity: 1}
    14% {
      opacity: 1;
    }
    13.9% {
      opacity: 1;
    }
    14% {
      opacity: 0;
    }
    100% {opacity:0;}
  }
  
  div.marzo-animated-carousel {
    font-weight: lighter;
    font-style: normal;
    width: 100%;
    height: 906pxBaskervville;
    position: relative;
    .marzo-animated-carousel-animation {
      h3 {
        opacity: 0;
        animation-name: bleed;
        animation-duration: 1.5s;
        animation-iteration-count: infinite;
        animation-direction: alternate; 
      }
    }
    h3 {
      color:#e4032e;
      top: 0;
      width: 100%;
      text-align: center;
      margin-top: 201px;
      font-size: 100px;
      position: absolute;
      text-transform:uppercase;
      &.pub-car-marz {
        margin-top: 81px;
        font-size: 265px;
        animation-delay: 1.6s;
        @media only screen and (max-width: 1706px) {
          margin-top:90px;
          font-size:110px;
        }
        @media only screen and (max-width: 1688px) {
          margin-top:151px;
          font-size: 169px;
        }
        @media only screen and (max-width: 1336px) {
          margin-top:81px;
          font-size:175px;
        }
        @media only screen and (max-width: 1200px) {
          margin-top:90px;
          font-size:110px;
        }
        @media only screen and (max-width: 768px) {
          margin-top:80px;
          font-size:70px;
        }
        @media only screen and (max-width: 480px) {
            margin-top:50px;
          font-size:40px;
        }
      }
      &.brand-car-marz {
        margin-top: 70px;
        font-size: 310px;
        animation-delay: 1.65s;
        @media only screen and (max-width: 1706px) {
          margin-top:75px;
           font-size:140px;
         }
         @media only screen and (max-width: 1688px) {
          margin-top:111px;
          font-size: 227px;
        }
        @media only screen and (max-width: 1336px) {
          margin-top:70px;
          font-size:203px;
        }
        @media only screen and (max-width: 1200px) {
         margin-top:75px;
          font-size:140px;
        }
        @media only screen and (max-width: 768px) {
          margin-top:70px;
          font-size:90px;
        }
        @media only screen and (max-width: 480px) {
            margin-top:48px;
          font-size:45px;
        }
      }
      &.dis-car-marz {
        margin-top: 0;
        font-size: 426px;
        animation-delay: 1.70s;
        @media only screen and (max-width: 1706px) {
          margin-top:38px;
           font-size:200px;
         }
         @media only screen and (max-width: 1688px) {
          margin-top:38px;
          font-size: 333px;
        }
        @media only screen and (max-width: 1336px) {
          margin-top: 0px;
          font-size:290px;
        }
        @media only screen and (max-width: 1200px) {
         margin-top:38px;
          font-size:200px;
        }
        @media only screen and (max-width: 768px) {
          margin-top:50px;
          font-size:120px
        }
        @media only screen and (max-width: 480px) {
            margin-top:35px;
          font-size:65px
        }
      }
      &.social-car-marz {
        margin-top: 140px;
        font-size: 185px;
        animation-delay: 1.75s;
        @media only screen and (max-width: 1706px) {
          margin-top:105px;
           font-size:85px;
         }
         @media only screen and (max-width: 1688px) {
          margin-top:185px;
          font-size: 118px;
        }
        @media only screen and (max-width: 1336px) {
          margin-top: 140px;
          font-size:116px;
        }
        @media only screen and (max-width: 1200px) {
         margin-top:105px;
          font-size:85px;
        }
        @media only screen and (max-width: 768px) {
          margin-top:90px;
          font-size:53px;
        }
        @media only screen and (max-width: 480px) {
            margin-top:60px;
          font-size:29px;
        }
      }
      &.events-car-marz {
        margin-top: 50px;
        font-size: 344px;
        animation-delay: 1.8s;
        @media only screen and (max-width: 1706px) {
          margin-top:63px;
           font-size:160px;
         }
         @media only screen and (max-width: 1688px) {
          margin-top: 128px;
          font-size: 200px;
        }
        @media only screen and (max-width: 1336px) {
          margin-top: 50px;
          font-size: 221px;
        }
        @media only screen and (max-width: 1200px) {
         margin-top:63px;
          font-size:160px;
        }
        @media only screen and (max-width: 768px) {
          margin-top:65px;
          font-size:100px
        }
        @media only screen and (max-width: 480px) {
            margin-top:45px;
          font-size:50px
        }
      }
      &.develop-car-marz {
        margin-top: 115px;
        font-size: 235px;
        animation-delay: 1.85s;
        @media only screen and (max-width: 1706px) {
          margin-top: 95px;
           font-size:105px;
         }
         @media only screen and (max-width: 1688px) {
          margin-top: 161px;
          font-size: 146px;
        }
        @media only screen and (max-width: 1336px) {
          margin-top: 83px;
          font-size: 158px;
        }
        @media only screen and (max-width: 1200px) {
         margin-top: 95px;
          font-size:105px;
        }
        @media only screen and (max-width: 768px) {
          margin-top:80px;
          font-size:70px;
        }
        @media only screen and (max-width: 480px) {
            margin-top:55px;
          font-size:35px;
        }
      }
      &.creativ-car-marz {
        margin-top: 113px;
        font-size: 239px;
        animation-delay: 1.9s;
        @media only screen and (max-width: 1706px) {
          margin-top: 92px;
           font-size: 105px;
         }
         @media only screen and (max-width: 1688px) {
          margin-top: 178px;
          font-size: 146px;
        }
        @media only screen and (max-width: 1336px) {
          margin-top: 113px;
          font-size: 147px;
        }
        @media only screen and (max-width: 1200px) {
         margin-top: 92px;
          font-size: 105px;
        }
        @media only screen and (max-width: 768px) {
          margin-top: 85px;
          font-size: 65px;
        }
        @media only screen and (max-width: 480px) {
            margin-top: 55px;
          font-size: 35px;
        }
      }
    }
    .marzo-animated-carousel-words {
      h3 {
        opacity: 0;
        animation-name: bleed2;
        animation-duration: 21s;
        animation-timing-function: ease-in-out;
        animation-iteration-count: infinite;
        &.pub-car-marz {
        animation-delay: 0s;
      }
      &.brand-car-marz {
        animation-delay: 3s;
      }
      &.dis-car-marz {
        animation-delay: 6s;
      }
      &.social-car-marz {
        animation-delay: 9s;
      }
      &.events-car-marz {
        animation-delay: 12s;
      }
      &.develop-car-marz {
        animation-delay: 15s;
      }
      &.creativ-car-marz {
        animation-delay: 18s;
      }
      }
    }
    
  }
  