.blog-header {
	height: 350px;
	background: url(assets/img/blog_marzo_640.jpg) center center;
	background-size: cover;
	padding: 50px 0;
	h1{
	  font-weight: 100;
	  font-size: 8rem;
	}
	@media screen and(min-width: $tiny){
	  background: url(assets/img/blog_marzo_1024.jpg) center center;
	}
	@media screen and(min-width: $small){
	  background: url(assets/img/blog_marzo.jpg) center center;
	}
}

.blog-marzo {
/* 	background: url(assets/img/quienes_somos_dos.jpg);
	background-position: center;
	background-size: cover; */
	background-color: $separatorColor;
	&__content {
		display: grid;
		grid-template-columns: repeat(1, 1fr);
		grid-gap: 1rem;
		padding: 5rem 1rem;
		@media screen and (min-width: $tiny) {
			padding: 5rem;
			grid-gap: 2rem;
		}
		@media screen and (min-width: $medium) {
			grid-template-columns: repeat(2, 1fr);
			grid-gap: 2.5rem;
		}
		@media screen and (min-width: $large) {
			grid-template-columns: repeat(3, 1fr);
		}
		@media screen and (min-width: $xlarge) {
			grid-gap: 5rem;
			padding: 20rem;
		}
	}
	&__item {
		background-color: $lightestGray;
		border: 20px solid $marzoNew;
		&,
		&:link,
		&:visited {
		  display: inline-block;
		  transition: all .2s;
		  outline: none;
		  p {
			  color: black;
			  font-size: 1.2rem;
			  @media screen and (min-width: $small) {
				font-size: 1.6rem;
			}
		  }
		  &:hover {
			transform: translateY(-3px);
			box-shadow: 0 1rem 2rem rgba(black,.2);
			text-decoration: none;
			p {
				color: black;
			}
		  }
		  &:active,
		  &:focus {
			  outline: none;
			  transform: translateY(-1px);
			  box-shadow: 0 .5rem 1rem rgba(black,.2);
		  }
		}
		h1 {
			color: $marzoNew;
			font-size: 1.6rem;
			font-family: 'theano_didotregular';
			@media screen and (min-width: $small) {
				font-size: 2rem;
			}
		}
	}
	&__img img {
		height: auto;
		width: 100%;
	}

	&__text {
		padding: 0 10px;
	}

	&__icon {
		display: flex;
		align-items: center;
		span {
			font-size: 1.2rem;
			padding-left: 5px;
			margin: 5px 0;
			@media screen and (min-width: $tiny) {
				font-size: 1.5rem;
			}
		}
	}
	&__next-posts, &__prev-posts {
		a {
			&,
			&:link,
			&:visited {
			  border-radius: 3px;
			  display: inline-block;
			  background: $lightestGray;
			  border: none;
			  font-size: 18px;
			  padding: 10px 20px 10px 20px;
			  text-decoration: none;
			  margin-bottom: 30px;
			  transition: all .2s;
			  &:hover {
				color: $marzoNew;
				transform: translateY(-3px);
				box-shadow: 0 1rem 2rem rgba(black,.2);
				text-decoration: none;
			  }
			  &:active,
			  &:focus {
				  outline: none;
				  transform: translateY(-1px);
				  box-shadow: 0 .5rem 1rem rgba(black,.2);
			  }
			}
		}
	}
}